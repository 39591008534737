import type { StoreConfigShelfBadgesQuery } from '@generated/graphql'
import { graphql, useStaticQuery } from 'gatsby'
import { createContext, useCallback, useContext, useMemo } from 'react'

import type { Badge, BadgesContextData } from './types'

const BadgesContext = createContext<BadgesContextData>({} as BadgesContextData)

const BadgesProvider: FCC = ({ children }) => {
  const rawBadges = useStaticQuery<StoreConfigShelfBadgesQuery>(graphql`
    query StoreConfigShelfBadges {
      cmsStoreConfig {
        shelf {
          badges {
            badges {
              displayName
              icon
              id
              variant
              textColor
              backgroundColor
            }
          }
        }
      }
    }
  `)

  const badges = useMemo(
    () => (rawBadges.cmsStoreConfig?.shelf?.badges?.badges ?? []) as Badge[],
    [rawBadges.cmsStoreConfig?.shelf?.badges?.badges]
  )

  const getBadgeById = useCallback(
    (id: number): Badge | undefined => {
      const badge = badges.find((findBage) => findBage?.id === id)

      return badge as Badge | undefined
    },
    [badges]
  )

  return (
    <BadgesContext.Provider value={{ badges, getBadgeById }}>
      {children}
    </BadgesContext.Provider>
  )
}

function useBadges(): BadgesContextData {
  const context = useContext(BadgesContext)

  if (!context) {
    throw new Error('useBadges must be used within a BadgesProvider')
  }

  return context
}

export { BadgesProvider, useBadges }
