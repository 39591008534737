import React, { useCallback, useMemo, useRef, useState } from 'react'
import Icon from 'src/components/ui/Icon'
import useOnClickOutside from 'src/sdk/ui/useOnClickOutside'

import type { SelectOption } from './SearchInput'
import * as S from './styles'

// const DropdownIndicator = (props: any) => {
//   return (
//     <components.DropdownIndicator {...props}>
//       <Icon name="dropdown-arrow" width={9} height={6} />
//     </components.DropdownIndicator>
//   )
// }

interface DepartmentSelectProps {
  options: SelectOption[]
  onChange?: (option: SelectOption) => void
}

const DepartmentSelect: React.FC<DepartmentSelectProps> = ({
  options,
  onChange,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(
    options.length > 0 ? 0 : -1
  )

  const containerRef = useRef(null)

  const [isOpen, setIsOpen] = useState(false)

  const selectedItem = useMemo(() => {
    if (selectedIndex >= 0) {
      return options[selectedIndex]
    }

    return null
  }, [options, selectedIndex])

  const handleOpen = useCallback(() => {
    setIsOpen((old) => !old)
  }, [])

  useOnClickOutside(containerRef, () => setIsOpen(false))

  const handleSelect = useCallback((index: number) => {
    setSelectedIndex(index)
  }, [])

  return (
    <S.SelectContainer ref={containerRef}>
      <S.SelectContent onClick={handleOpen}>
        <S.SelectValueWrapper>
          <S.SelectValue title={selectedItem?.label ?? ''}>
            {selectedItem?.label}
          </S.SelectValue>
          <Icon name="dropdown-arrow" width={9} height={6} />
        </S.SelectValueWrapper>
      </S.SelectContent>
      {isOpen && (
        <S.SelectOptionsList>
          {options.map(({ label, value }, index) => (
            <S.SelectOptionsListItem
              key={`category-select-${String(value)}`}
              selected={value === selectedItem?.value}
              onClick={() => {
                handleSelect(index)
                onChange?.({ label, value })
                setIsOpen(false)
              }}
            >
              {label}
            </S.SelectOptionsListItem>
          ))}
        </S.SelectOptionsList>
      )}
    </S.SelectContainer>
  )
}

export default DepartmentSelect
