import {
  createContext,
  lazy,
  Suspense,
  useCallback,
  useContext,
  useState,
} from 'react'
import { navigate } from 'gatsby'
import { useSession, validateSession } from 'src/sdk/session'

import type { AuthContextData } from './types'

const LoginModal = lazy(
  () => import('../../components/common/Header/UserBox/LoginModal')
)

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

const AuthProvider: FCC = ({ children }) => {
  // const {
  //   data: userData,
  //   mutate,
  //   isValidating,
  // } = useQuery<PersonQueryQuery, PersonQueryQueryVariables>(
  //   query,
  //   {},
  //   {
  //     fetchOptions: { method: 'POST' },
  //     fallbackData: { person: null },
  //   }
  // )

  const { isValidating, ...session } = useSession()
  const { person: user } = session

  // const checkCookie = useCallback(async () => {
  //   mutate()
  //   // const reqData = await request<PersonQueryQuery, PersonQueryQueryVariables>(
  //   //   query,
  //   //   {},
  //   //   {
  //   //     fetchOptions: { method: 'POST' },
  //   //   }
  //   // )

  //   // const person = reqData?.person

  //   // console.log({})

  //   // if (cachedUser !== person) {
  //   //   setSession({
  //   //     ...session,
  //   //     user: person,
  //   //   })
  //   // }

  //   // return person
  // }, [mutate])

  const signOut = useCallback(async () => {
    await fetch('/api/logout')
    validateSession(session)

    // mutate(
    //   async () => {
    //     await axios.get('/api/logout')

    //     return optimisticData
    //   },
    //   {
    //     optimisticData,
    //   }
    // )
  }, [session])

  const [isModalOpen, setIsModalOpen] = useState(false)

  // const handleOpenModal = useCallback(() => setIsModalOpen(true), [])

  const handleCloseModal = useCallback(() => setIsModalOpen(false), [])

  const signIn = useCallback(async () => {
    // handleOpenModal()
    navigate('/login')
  }, [])

  const handleModalLogin = useCallback(() => {
    validateSession(session)
  }, [session])

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        user,
        checkCookie: async () => {},
        isValidating,
      }}
    >
      {children}
      {isModalOpen && (
        <Suspense fallback={null}>
          <LoginModal
            onClose={handleCloseModal}
            onLoginDone={handleModalLogin}
          />
        </Suspense>
      )}
    </AuthContext.Provider>
  )
}

function useAuth(): AuthContextData {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider')
  }

  return context
}

export { AuthProvider, useAuth }
