import { InstitucionalContainer } from './styles'
import LeftContent from './LeftContent'
import MidContent from './MidContent'
import RightContent from './RightContent'

function Institucional() {
  return (
    <InstitucionalContainer>
      <LeftContent />
      <MidContent />
      <RightContent />
    </InstitucionalContainer>
  )
}

export default Institucional
