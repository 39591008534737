import type { StoreConfigHeaderInfoBarQuery } from '@generated/graphql'
import { graphql, useStaticQuery } from 'gatsby'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { globalHistory } from '@reach/router'

import type { InfoBarContextData, InfoBarData } from './types'

const InfoBarContext = createContext<InfoBarContextData>(
  {} as InfoBarContextData
)

const InfoBarProvider: FCC = ({ children }) => {
  const rawDefaultInfoBar =
    useStaticQuery<StoreConfigHeaderInfoBarQuery>(graphql`
      query StoreConfigHeaderInfoBar {
        cmsStoreConfig {
          header {
            infoBar {
              links {
                bold
                hoverPanel {
                  title
                  subLinks {
                    link
                    text
                    highlighted
                  }
                  images {
                    leftImage {
                      alt
                      link
                      srcSet
                    }
                    mosaicBottomRightImage {
                      alt
                      link
                      srcSet
                    }
                    mosaicLeftImage {
                      alt
                      link
                      srcSet
                    }
                    mosaicTopRightImage {
                      alt
                      srcSet
                      link
                    }
                  }
                }
                link
                newTab
                text
              }
            }
          }
        }
      }
    `)

  const [data, setData] = useState(
    rawDefaultInfoBar.cmsStoreConfig?.header?.infoBar?.links as InfoBarData
  )

  const reset = useCallback(
    () =>
      setData(
        rawDefaultInfoBar.cmsStoreConfig?.header?.infoBar?.links as InfoBarData
      ),
    [rawDefaultInfoBar.cmsStoreConfig?.header?.infoBar?.links]
  )

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') reset()
    })
  }, [reset])

  // const infoBar = useMemo(
  //   () => ,
  //   [rawDefaultInfoBar.cmsStoreConfig?.header?.infoBar?.links]
  // )

  return (
    <InfoBarContext.Provider value={{ data: data ?? [], setData, reset }}>
      {children}
    </InfoBarContext.Provider>
  )
}

function useInfoBar(): InfoBarContextData {
  const context = useContext(InfoBarContext)

  if (!context) {
    throw new Error('useInfoBar must be used within a InfoBarProvider')
  }

  return context
}

export { InfoBarProvider, useInfoBar }
