function dataLayer(data: Record<string, any> & { event: string }) {
  // if (!window.dataLayer) return

  // setTimeout(() => {

  try {
    console.log('Pushing to dataLayer: ', data)

    window.dataLayer.push(data)
  } catch {
    console.log('Error pushing to dataLayer')
  }

  // }, 2000)
}

export default dataLayer
