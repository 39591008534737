import type { StoreConfigHeaderFreeShippingBarQuery } from '@generated/graphql'
import { graphql, useStaticQuery } from 'gatsby'
import { useMemo } from 'react'
import { useCart } from 'src/sdk/cart'
import formatCurrency from 'src/utils/formatCurrency'

import { Container, Progress } from './styles'

const FreeShippingBar: FCC = () => {
  const { items } = useCart()

  const freeShippingBarConfig =
    useStaticQuery<StoreConfigHeaderFreeShippingBarQuery>(graphql`
      query StoreConfigHeaderFreeShippingBar {
        cmsStoreConfig {
          header {
            freeShippingBar {
              amountOffset
            }
          }
        }
      }
    `)

  const offset =
    freeShippingBarConfig.cmsStoreConfig?.header?.freeShippingBar
      ?.amountOffset ?? 0

  const cartTotalValue = useMemo(
    () =>
      items.reduce((acc, item) => acc + item.price * item.quantity * 100, 0),
    [items]
  )

  const difference = offset - cartTotalValue

  return difference > 0 ? (
    <Container className="free-shipping-bar__container">
      <Progress
        className="free-shipping-bar__progress"
        value={(cartTotalValue / offset) * 100}
      />
      <span>
        Faltam {formatCurrency(difference / 100)} para você ganhar frete grátis
      </span>
    </Container>
  ) : (
    <Container className="free-shipping-bar__container">
      <Progress className="free-shipping-bar__progress" value={100} />
      <span>Você ganhou frete grátis!</span>
    </Container>
  )
}

export default FreeShippingBar
