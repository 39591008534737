import styled from 'styled-components'

export const SocialContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 8px;

  .icon-miess-red__container {
    margin-bottom: 15px;
  }

  p {
    font-family: 'Baloo 2';
    max-width: 200px;
    font-size: 12px;
    line-height: 12px;
    text-align: justify;
  }
  .hide-on-desktop {
    visibility: hidden;
  }

  @media screen and (max-width: 1024px) {
    .mLogo {
      display: none;
    }

    p {
      max-width: unset;
      margin-bottom: 35px;
      width: 100%;
      line-height: 15px;
      text-align: justify;
      font-size: 12px;
    }

    .hide-on-desktop {
      visibility: visible;
      color: ${({ theme }) => theme.colors.secondary_1};
      width: 100%;
      margin: 10px 0;
    }
  }
`

export const MediaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  a {
    display: inline-flex;
    position: relative;
    overflow: hidden;
  }
  @media screen and (max-width: 990px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;

    a {
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 1024px) {
    display: none;
  }
`
