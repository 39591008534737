import { css } from 'styled-components'

import { mediaMax } from './utils/mediaMax'
import { urlIcon } from './utils/urlIcon'

const swiper = css`
  .swiper {
    width: 100%;
    &-arrow {
      position: absolute;
      width: 34px;
      height: 38px;
      // top: calc(50% - 20px);
      top: 50%;

      transform: translateY(-50%);
      border: none;
      outline: none;
      background-color: transparent;
      z-index: 1;
      transition: opacity 0.2s;

      /* ${mediaMax(
        css`
          top: 220px;
        `,
        470
      )} */

      &.swiper-button-disabled,
      &.swiper-button-lock {
        opacity: 0 !important;
        cursor: default;

        &:hover {
          .arrow-fill {
            opacity: 0;
          }
        }
      }
      .arrow-fill {
        position: absolute;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }

      &:hover {
        .arrow-fill {
          opacity: 1;
        }
      }

      &.prev-arrow {
        left: 0;

        &.circle {
          left: -10px;
        }
      }
      &.next-arrow {
        right: 0;
        transform: translateY(-50%) rotate(180deg);

        &.circle {
          right: -10px;
        }
      }
      &.circle {
        display: flex;
        width: 65px;
        height: 65px;
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        align-items: center;
        justify-content: center;

        svg {
          width: 34px;
          height: 38px;
        }

        &.next-arrow {
          box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
        }
      }

      ${mediaMax(
        css`
          width: 30px;
          height: 33px;
          &.circle {
            width: 48px;
            height: 48px;
            svg {
              width: 23px;
              height: 23px;
            }
          }
        `,
        680
      )}
    }

    &-pagination {
      &-bullet {
        cursor: pointer;
        min-width: 18px;
        max-height: 18px;
        display: flex;

        ${urlIcon('bullet')}

        &::after {
          width: 18px;
        }

        &-active {
          ${urlIcon('bullet-active', true)}
        }

        & + & {
          margin-left: 3px;
        }
      }
    }
  }

  .swiper:not(.full-width-banner):not(.swiper-initialized) {
    &.grid {
      & > .swiper-wrapper {
        flex-wrap: wrap;
      }
    }

    .swiper {
      &-slide {
        &-duplicate {
          display: none !important;
        }
      }
      &-wrapper {
        justify-content: space-between;
      }

      /* &-initialized {
        .swiper {
          &-slide {
            &-duplicate {
              display: flex !important;
            }
          }
          &-wrapper {
            justify-content: unset;
          }
        }
      } */
    }
  }
`

export default swiper
