import type { StoreConfigHeaderCategoryMenuQuery } from '@generated/graphql'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useCallback, useMemo, useState } from 'react'
import { Image } from 'src/components/ui/Image'
import { useCategoryTree } from 'src/hooks/categoryTree'
import dataLayer from 'src/utils/dataLayer'
import { toClassname } from 'src/utils/toClassname'

import { CustomLink } from '../../CustomLink'
import {
  CategoryListContainer,
  Container,
  ImageWrapper,
  RootCategoryList,
  RootCategoryListItem,
} from './styles'

interface NavLinksProps {
  isInvisible?: boolean
}

const Navlinks: FCC<NavLinksProps> = ({ isInvisible }) => {
  const categoryMenuConfig =
    useStaticQuery<StoreConfigHeaderCategoryMenuQuery>(graphql`
      query StoreConfigHeaderCategoryMenu {
        cmsStoreConfig {
          header {
            categoryMenu {
              categories {
                highlighted
                id
                srcSet
              }
              customLinks {
                highlighted
                link
                newTab
                text
              }
            }
          }
        }
      }
    `)

  const [forceClose, setForceClose] = useState(false)

  const close = useCallback(() => {
    setForceClose(true)
    // setTimeout(() => setForceClose(false))
  }, [])

  const { getCategoryById } = useCategoryTree()
  const parsedCategories = useMemo(
    () =>
      categoryMenuConfig.cmsStoreConfig?.header?.categoryMenu?.categories?.map(
        (category) => ({
          ...getCategoryById(String(category?.id)),
          highlighted: category?.highlighted,
          srcSet: category?.srcSet,
        })
      ),
    [
      categoryMenuConfig.cmsStoreConfig?.header?.categoryMenu?.categories,
      getCategoryById,
    ]
  )

  const shouldBeFull = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      (categoryMenuConfig.cmsStoreConfig?.header?.categoryMenu?.categories
        ?.length ?? 0) +
        (categoryMenuConfig.cmsStoreConfig?.header?.categoryMenu?.customLinks
          ?.length ?? 0) >
      5,
    [
      categoryMenuConfig.cmsStoreConfig?.header?.categoryMenu?.categories
        ?.length,
      categoryMenuConfig.cmsStoreConfig?.header?.categoryMenu?.customLinks
        ?.length,
    ]
  )

  const handleMouseLeave = useCallback(() => {
    setForceClose(false)
  }, [])

  // useEffect(() => {
  //   console.log({ isInvisible })
  // }, [isInvisible])

  const handleCategoryClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      dataLayer({
        event: 'clicou no menu',
        clicou_no_menu: e.currentTarget.innerText,
      })
    },
    []
  )

  return (
    <Container isInvisible={isInvisible} className="navlinks">
      <RootCategoryList full={shouldBeFull}>
        {parsedCategories?.map((x) => (
          <RootCategoryListItem
            key={`${x.slug}-${x.id}`}
            data-id={x.id}
            isHighlighted={!!x.highlighted}
            onMouseLeave={handleMouseLeave}
            onClick={(e) => handleCategoryClick(e)}
          >
            {x.children?.length && (
              <CategoryListContainer
                className={toClassname([
                  'list-container',
                  forceClose && 'close',
                ])}
              >
                <ul>
                  {x.children.map((child) => (
                    <li data-id={child.id} key={child.id}>
                      <CustomLink to={`/${child.slug}`} onClick={close}>
                        {child.name}
                      </CustomLink>
                    </li>
                  ))}
                  <li className="see-all">
                    <CustomLink to={`/${x.slug}`} onClick={close}>
                      Ver todos
                    </CustomLink>
                  </li>
                </ul>
                <ImageWrapper>
                  {x.srcSet && (
                    <CustomLink to={`/${x.slug}`} onClick={close}>
                      <Image
                        // src="http://miess.vtexassets.com/arquivos/ids/234723/"
                        src={x.srcSet}
                        alt={x.name ?? ''}
                        title={x.name ?? ''}
                        loading="lazy"
                        // sourceWidth={300}
                        width={300}
                        height={400}
                        options={{
                          fitIn: true,
                          // filters: {
                          //   quality: '100',
                          // },
                        }}
                      />
                    </CustomLink>
                  )}
                </ImageWrapper>
              </CategoryListContainer>
            )}
            <CustomLink to={`/${x.slug}`} onClick={close}>
              {x.name}
            </CustomLink>
          </RootCategoryListItem>
        ))}
        {categoryMenuConfig.cmsStoreConfig?.header?.categoryMenu?.customLinks?.map(
          (customLink, index) => (
            <RootCategoryListItem
              key={index}
              onClick={(e) => handleCategoryClick(e)}
              isHighlighted={!!customLink?.highlighted}
            >
              <CustomLink
                to={customLink?.link ?? '/'}
                newTab={!!customLink?.newTab}
              >
                {customLink?.text}
              </CustomLink>
            </RootCategoryListItem>
          )
        )}
      </RootCategoryList>
    </Container>
  )
}

export default Navlinks
