import { useMemo } from 'react'
import type { ImgHTMLAttributes } from 'react'

import { urlBuilder } from './thumborUrlBuilder'
import type { ThumborOptions } from './thumborUrlBuilder'

export interface ImageOptions extends ImgHTMLAttributes<HTMLImageElement> {
  src: string
  width: number
  height: number
  options?: ThumborOptions
  noFactors?: boolean
  sourceWidth?: number
  sourceHeight?: number
  factors?: number[]
  // srcSetData?: Record<number, number>
  srcSetData?: number[]
}

const FACTORS = [1, 2, 3]

export const useImage = ({
  src: baseUrl,
  width,
  height,
  options = {},
  noFactors,
  sourceWidth,
  sourceHeight,
  factors = FACTORS,
  srcSetData,
  ...rest
}: ImageOptions): ImgHTMLAttributes<HTMLImageElement> => {
  const { srcSet, src } = useMemo(() => {
    const builder = urlBuilder(baseUrl, options)

    const srcs = srcSetData
      ? srcSetData.map((value) => {
          return `${builder(
            value,
            ((sourceHeight ?? height) * value) / (sourceWidth ?? width)
          )} ${value}w`
        })
      : factors.map((factor) => {
          const rescaledWidth = (sourceWidth ?? width) * factor

          return `${builder(
            rescaledWidth,
            (sourceHeight ?? height) * factor
          )} ${rescaledWidth}w`
        })

    const LARGE_FACTOR = factors[factors.length - 1]

    const factor = noFactors ? 1 : LARGE_FACTOR

    return {
      src: builder(
        (sourceWidth ?? width) * factor,
        (sourceHeight ?? height) * factor
      ),
      srcSet: srcs.join(', '),
    }
  }, [
    baseUrl,
    options,
    srcSetData,
    factors,
    noFactors,
    sourceWidth,
    width,
    sourceHeight,
    height,
  ])

  return {
    src,
    srcSet,
    width: `${width}px`,
    height: `${height}px`,
    ...rest,
  }
}
