/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { StoreConfigHeaderSidebarMenuQuery } from '@generated/graphql'
import { graphql, useStaticQuery } from 'gatsby'
import { useCallback, useMemo, useState } from 'react'
import Icon from 'src/components/ui/Icon'
import { useAuth } from 'src/hooks/auth'
import { useCategoryTree } from 'src/hooks/categoryTree'
import { useInfoBar } from 'src/hooks/infoBar'
import { useUI } from 'src/sdk/ui/Provider'
import { useFadeEffect } from 'src/sdk/ui/useFadeEffect'
import { stringToSanitizedHtml } from 'src/utils/stringToSanitizedHtml'
import NewLogo from 'src/components/ui/Logo/NewLogo'

import storeConfig from '../../../../../store.config'
import CustomLink from '../../CustomLink/CustomLink'
import * as S from './styles'

interface LinkItem {
  link: string
  text: string
}

const SideBar: FCC = () => {
  const [links, setLinks] = useState<LinkItem[]>([])
  const [title, setTitle] = useState('')
  const [seeMoreLink, setSeeMoreLink] = useState('')
  const { user } = useAuth()

  const { data } = useInfoBar()

  const sidebarQuery =
    useStaticQuery<StoreConfigHeaderSidebarMenuQuery>(graphql`
      query StoreConfigHeaderSidebarMenu {
        cmsStoreConfig {
          header {
            categoryMenu {
              categories {
                highlighted
                id
              }
              customLinks {
                highlighted
                link
                newTab
                text
              }
            }
            headerInformation {
              information {
                icon
                link
                text
              }
            }
          }
        }
      }
    `)

  const { getCategoryById } = useCategoryTree()

  const parsedCategories = useMemo(
    () =>
      sidebarQuery.cmsStoreConfig?.header?.categoryMenu?.categories?.map(
        (category) => ({
          ...getCategoryById(String(category?.id)),
          highlighted: category?.highlighted,
        })
      ),
    [
      sidebarQuery.cmsStoreConfig?.header?.categoryMenu?.categories,
      getCategoryById,
    ]
  )

  const handleMenuItemClick = useCallback(
    (id: string, isCategory = true) => {
      if (isCategory) {
        const category = parsedCategories?.find((cat) => cat.id === id)

        if (!category) return

        setLinks(
          category.children?.map((catChild) => ({
            link: `/${catChild.slug}`,
            text: catChild.name,
          })) ?? []
        )
        setTitle(category.name ?? '')
        setSeeMoreLink(`/${category.slug}`)
      } else {
        const infoItem = data.find(
          (link) => `${link?.link}-${link?.text}` === id
        )

        if (!infoItem) return

        setLinks(
          infoItem.hoverPanel?.subLinks?.map((infoItemChild) => ({
            link: infoItemChild?.link ?? '',
            text: infoItemChild?.text ?? '',
          })) ?? []
        )
        setTitle(infoItem.text ?? '')
        setSeeMoreLink(infoItem.link ?? '/')
      }
    },
    [data, parsedCategories]
  )

  const { closeNavbar } = useUI()

  const handleBack = useCallback(() => {
    setLinks([])
    setTitle('')
  }, [])

  const handleClose = useCallback(() => {
    closeNavbar()
    handleBack()
  }, [handleBack, closeNavbar])

  const { fade, fadeOut } = useFadeEffect()

  const listInformation = useMemo(
    () =>
      sidebarQuery.cmsStoreConfig?.header?.headerInformation?.information ?? [],
    [sidebarQuery.cmsStoreConfig?.header?.headerInformation?.information]
  )

  return (
    <S.StyledSlideOver
      isOpen
      fade={fade}
      onDismiss={fadeOut}
      // onDismissTransition={(callback) => (dismissTransition.current = callback)}
      direction="leftSide"
      size="partial"
      onTransitionEnd={() => fade === 'out' && closeNavbar()}
    >
      <S.Content className="no-scroll">
        <S.LogoBox>
          <CustomLink className="m-1" to="/">
            <NewLogo />
          </CustomLink>
        </S.LogoBox>

        {links.length > 0 ? (
          <>
            <S.TitleWrapper onClick={handleBack}>
              <Icon name="chevron-left" width={7} height={14} />
              voltar
            </S.TitleWrapper>
            <S.SubLinkList>
              <S.SubLinkListItem highlight onClick={handleClose}>
                <CustomLink to={seeMoreLink}>Ver todos em {title}</CustomLink>
              </S.SubLinkListItem>
              {links.map((link) => (
                <S.SubLinkListItem
                  key={`${link?.link}-${link?.text}`}
                  onClick={handleClose}
                >
                  <CustomLink to={link.link}>{link.text}</CustomLink>
                </S.SubLinkListItem>
              ))}
            </S.SubLinkList>
          </>
        ) : (
          <>
            <S.UserWrapper>
              <CustomLink
                // to={`${storeConfig.loginUrl}?returnUrl=https://www.miess.com.br`}
                to="/login"
                rel="nofollow"
                // onClick={(e) => {
                // const search = new URLSearchParams(window.location.search)
                // if (
                //   process.env.NODE_ENV !== 'production' ||
                //   search.get('devtest')
                // ) {
                // e.preventDefault()
                // signIn()
                // }
                // }}
              >
                <Icon name="user" width={22} height={23} />
              </CustomLink>

              <CustomLink
                to={user?.id ? `${storeConfig.accountUrl}` : '/login'}
                rel="nofollow"
                // onClick={(e) => {
                //   if (process.env.NODE_ENV !== 'production' && !user?.id) {
                //     e.preventDefault()
                //     signIn()
                //   }
                // }}
              >
                <p>
                  <b>Bem vindo :)</b>
                  <span>
                    {user?.id
                      ? user.givenName || user.email
                      : 'Entre ou cadastre-se'}
                  </span>
                </p>
              </CustomLink>
            </S.UserWrapper>
            <S.HorizontalRow />
            <S.MenuList baloo>
              {parsedCategories?.map((cat) => (
                <S.MenuListItem
                  key={`${cat.slug}-${cat.id}`}
                  highlight={!!cat.highlighted}
                >
                  <CustomLink to={`/${cat.slug}` ?? '/'} onClick={handleClose}>
                    {cat.name}
                  </CustomLink>
                  <S.MenuListItemButton
                    onClick={() => handleMenuItemClick(cat.id as string)}
                  >
                    +
                  </S.MenuListItemButton>
                </S.MenuListItem>
              ))}
              {sidebarQuery.cmsStoreConfig?.header?.categoryMenu?.customLinks?.map(
                (customLink, index) => (
                  <S.MenuListItem
                    key={index}
                    highlight={!!customLink?.highlighted}
                  >
                    <CustomLink
                      to={customLink?.link ?? '/'}
                      newTab={!!customLink?.newTab}
                    >
                      {customLink?.text}
                    </CustomLink>
                  </S.MenuListItem>
                )
              )}
            </S.MenuList>
            <S.HorizontalRow />
            <S.MenuList>
              {data.map((link) => (
                <S.MenuListItem
                  key={`${link?.link}-${link?.text}`}
                  bold={!!link?.bold}
                >
                  {link?.hoverPanel?.subLinks?.length ? (
                    <span
                      tabIndex={-1}
                      role="button"
                      onClick={() =>
                        handleMenuItemClick(
                          `${link?.link}-${link?.text}`,
                          false
                        )
                      }
                    >
                      {link?.text}
                    </span>
                  ) : (
                    <CustomLink
                      to={link?.link ?? '/'}
                      newTab={!!link?.newTab}
                      onClick={handleClose}
                    >
                      {link?.text}
                    </CustomLink>
                  )}
                </S.MenuListItem>
              ))}
            </S.MenuList>

            {links.length === 0 && (
              <S.FooterBoxList>
                {listInformation.map(({ link, text, icon }: any) => (
                  <S.FooterBoxListItem key={text}>
                    <CustomLink onClick={handleClose} to={link}>
                      {stringToSanitizedHtml(icon)}
                      {text}
                    </CustomLink>
                  </S.FooterBoxListItem>
                ))}
              </S.FooterBoxList>
            )}
          </>
        )}
      </S.Content>

      <S.CloseButton type="button" onClick={handleClose}>
        <Icon name="close-2" size={17} />
      </S.CloseButton>
    </S.StyledSlideOver>
  )
}

export default SideBar
