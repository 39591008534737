import { Link as GatsbyLink } from 'gatsby'
import type { GatsbyLinkProps } from 'gatsby'
import React from 'react'

// This is coming from Gatsby's internals: https://github.com/gatsbyjs/gatsby/blob/2975c4d1271e3da52b531ad2f49261c362e5ae13/packages/gatsby-link/src/index.js#L42-L46.
const isExternalLink = (path: string) =>
  path?.startsWith(`http://`) ||
  path?.startsWith(`https://`) ||
  path?.startsWith(`//`) ||
  path?.startsWith(`tel:`) ||
  path?.startsWith(`mailto:`) ||
  path?.startsWith(`www.`)

interface CustomLinkProps<T> extends Omit<GatsbyLinkProps<T>, 'to'> {
  newTab?: boolean
  newTabIfExternal?: boolean
  to: string | null
}

const newTabAttrs = {
  target: '_blank',
  rel: 'noopener noreferrer',
}

function Link<TState>({
  children,
  newTab,
  newTabIfExternal,
  ...props
}: React.PropsWithoutRef<CustomLinkProps<TState>>) {
  if (props.to === null) {
    return <>{children}</>
  }

  if (props.target === '_blank' || newTab) {
    return (
      <a {...props} href={props.to} {...newTabAttrs}>
        {children as React.ReactNode}
      </a>
    )
  }

  if (isExternalLink(props.to)) {
    return (
      <a
        {...props}
        href={props.to}
        {...(props.target === '_blank' || newTab || newTabIfExternal
          ? newTabAttrs
          : {})}
      >
        {children as React.ReactNode}
      </a>
    )
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <GatsbyLink<TState> {...props}>{children}</GatsbyLink>
}

export default Link
