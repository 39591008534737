import styles from './logo.module.scss'

function NewLogo() {
  return (
    <span
      className={styles.fsLogo}
      data-fs-logo
      role="img"
      aria-label="Miess Logo"
    />
  )
}

export default NewLogo
