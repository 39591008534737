import { createGlobalStyle, css, keyframes } from 'styled-components'

import states from './states'
import swiper from './swiper'
import { mediaMin } from './utils/mediaMin'

const backgroundBlack = keyframes`
  from {
    background-color: transparent;
    backdrop-filter: blur(0);
  }
  to {
    background-color: #0000004d;
    backdrop-filter: blur(4px);
  }
`

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  /* Fix blurry images */
  @media screen and (-webkit-min-device-pixel-ratio:0)
  and (min-resolution:.001dpcm) {
    img {
      image-rendering: -webkit-optimize-contrast !important;
    }
  }
  @media not all and (min-resolution:.001dpcm)
  { @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    img {
      image-rendering: unset !important;
    }
  }}

  body {
    background: white;
    color: ${(props) => props.theme.colors.grey_2};
    font: 400 15px 'Baloo 2', sans-serif;
    line-height: 1;
    /* overflow-x: hidden; */
    -webkit-font-smoothing: antialiased !important;
  }

  html {
    overflow-x: hidden
  }

   body,html{
     scroll-behavior:smooth;
   }
  a {
    text-decoration: none;
    color: inherit;
  }

  table {
    border-spacing: 0;
    border-collapse: collapse;
  }

  [type=button], [type=reset], [type=submit], button {
    cursor: pointer;
    -webkit-appearance: button;
    background-color: initial;
    background-image: none;
    outline: none;
    border: none;
  }

  /* .gatsby-image-wrapper {
    width: max-content;
  } */

  ${mediaMin(
    css`
      :not(.no-scroll)::-webkit-scrollbar-track {
        background-color: ${({ theme }) => theme.colors.secondary_1};
        border-radius: 10px;
      }
      :not(.no-scroll)::-webkit-scrollbar {
        width: 10px;
      }
      :not(.no-scroll)::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colors.primary_0};
        border-radius: 10px;
      }
    `,
    1024
  )}


  .cart-foreshadow {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 5;
    -webkit-animation: ${backgroundBlack} 300ms forwards;
    animation: ${backgroundBlack} 300ms forwards;
  }

  .custom-modal {
    width: unset !important;
    min-width: unset !important;
    max-width: unset !important;

    display: flex;
    align-items: center;
    justify-content: center;
    inset: 0 !important;

    ${mediaMin(
      css`
        width: fit-content !important;
      `,
      1023
    )}
  }


  // React Modal
  .react-modal-overlay {
    background: rgba(0, 0, 0, 0.3);


    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${swiper}
  ${states}
`
