module.exports = {
  // Theming
  theme: 'custom-theme',

  // Ecommerce Platform
  platform: 'vtex',

  // Platform specific configs for API
  api: {
    storeId: 'miess',
    account: 'miess',
    environment: 'vtexcommercestable',
    workspace: process.env.EPLUS_WORKSPACE || 'master',
    hideUnavailableItems: false,
  },

  // Default channel
  session: {
    currency: {
      code: 'BRL',
      symbol: 'R$',
    },
    locale: 'pt-BR',
    channel: '{"salesChannel":"1","regionId":""}',
    country: 'BRA',
    postalCode: null,
    person: null,
  },

  // Production URLs
  storeUrl: 'https://www.miess.com.br',
  checkoutUrl: 'https://secure.miess.com.br/checkout',
  secureSubdomain: 'https://secure.miess.com.br',
  loginUrl: 'https://secure.miess.com.br/faststore/login',
  accountUrl: 'https://secure.miess.com.br/faststore/account',
  ordersUrl: 'https://secure.miess.com.br/faststore/account/orders',

  // Lighthouse CI
  lighthouse: {
    server: process.env.BASE_SITE_URL || 'http://localhost:9000',
    pages: {
      home: '/',
      pdp: '/gel-hot-beijavel-para-massagem-15ml-for-sexy-ht15/p',
      collection: '/sex-shop',
      instAboutUs: '/institucionais/AntesCompra/QuemSomos',
    },
  },

  // E2E CI
  cypress: {
    pages: {
      home: '/',
      pdp: '/gel-hot-beijavel-para-massagem-15ml-for-sexy-ht15/p',
      collection: '/sex-shop',
      collection_filtered:
        '/lingerie/?category-1=lingerie&marca=hot-flowers&facets=category-1%2Cmarca',
      search: '/s?q=gel',
      intAboutUs: '/institucionais/AntesCompra/QuemSomos',
    },
  },

  analytics: {
    // https://developers.google.com/tag-platform/tag-manager/web#standard_web_page_installation,
    // gtmContainerId: 'GTM-5PCJKR3', // Old GTM
    // gtmContainerId: 'GTM-MH5RLJ8', // New GTM
    // gtmContainerId: 'GTM-T6J3VTG', // New GTM
    gtmContainerId: 'GTM-5K43K9P', // New EMPTY GTM
    // gtmContainerId: undefined, // NO GTM
  },
}
