import Footer from 'src/components/common/Footer'
import Header from 'src/components/common/Header'
import type { PropsWithChildren } from 'react'
// import Toast from 'src/components/common/Toast'

import GlobalStyle from './styles/global'

// import './styles/fonts.css'

const GlobalStyleProxy: any = GlobalStyle

function Layout({ children }: PropsWithChildren) {
  // useEffect(() => {
  //   const w = window as any

  //   if (!w) {
  //     return
  //   }

  //   windows._trustvox_shelf_rate = windows._trustvox_shelf_rate || []

  //   windows._trustvox_shelf_rate.push(['_storeId', '20106'])
  //   windows._trustvox_shelf_rate.push(['_productContainer', 'body'])
  //   windows._trustvox_shelf_rate.push(['_watchSubtree', true])
  // }, [])

  return (
    <div id="layout">
      <GlobalStyleProxy />
      <Header />
      <main>{children}</main>
      <Footer />
      {/* <Toast /> */}
      {/* {cart && (
        <button onClick={handleClickForeShadow} className="cart-foreshadow" />
      )} */}
    </div>
  )
}

export default Layout
