import styled, { css } from 'styled-components'

export const PaymentContainer = styled.div<{ open: boolean }>`
  @media screen and (max-width: 940px) {
    padding: 12px 0;
    border-top: 1px solid ${({ theme }) => theme.colors.secondary_1};
    display: flex;
    flex-direction: column;

    ${({ open }) =>
      open &&
      css`
        .upIcon {
          transform: rotate(180deg);
        }
      `}
    button {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .upIcon {
        width: 10px;
        display: inline-flex;
        transition: transform 0.2s;
      }
    }
  }
  h3 {
    font-size: 16px;
    line-height: 25px;
    color: white;
  }
`

export const PaymentDropDown = styled.div`
  span {
    font-family: 'Baloo 2';
    font-size: 12px;
  }
  @media screen and (max-width: 940px) {
    .dropdownContent {
      width: 100%;
      max-height: 0px;
      overflow: hidden;
      opacity: 0;
      visibility: hidden;
      transition: all 0.5s ease;
      margin: 0;
      margin-left: -16px;
    }
    .visible {
      transition: all 0.5s ease;
      max-height: 200px;
      opacity: 1;
      visibility: visible;
      margin-left: 0;
    }
  }
`
export const Payments = styled.div`
  @media screen and (max-width: 940px) {
    margin-top: 5px;
    margin-bottom: 10px;
  }
`

export const PaymentsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
`
export const PaymentsTerm = styled.div``
