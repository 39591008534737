import Icon from 'src/components/ui/Icon'
import { useAuth } from 'src/hooks/auth'

import storeConfig from '../../../../../store.config'
import * as S from './styles'

// const logged = false
// const email = 'bruno@agenciaeplus.com.br'

const UserBox: FCC = () => {
  const { user, signOut } = useAuth()

  return (
    <>
      <S.Container>
        <S.Content>
          <Icon name="user" width={22} height={23} />
          <S.Box className="box">
            {!user && (
              <S.BoxItemLink
                // to={`${storeConfig.loginUrl}?returnUrl=https://www.miess.com.br`}
                rel="nofollow"
                to="/login"
                // onClick={(e) => {
                // const search = new URLSearchParams(window.location.search)

                // if (
                //   process.env.NODE_ENV !== 'production' ||
                //   search.get('devtest')
                // ) {
                // e.preventDefault()
                // signIn()
                // }
                // }}
              >
                Entrar/Cadastrar
              </S.BoxItemLink>
            )}
            <S.BoxItemLink to={`${storeConfig.accountUrl}`} rel="nofollow">
              Minha conta
            </S.BoxItemLink>
            <S.BoxItemLink to={`${storeConfig.ordersUrl}`} rel="nofollow">
              Meus pedidos
            </S.BoxItemLink>
            {user && (
              <S.BoxItemLink
                to="/"
                logout
                onClick={(e) => {
                  e.preventDefault()
                  signOut()
                }}
              >
                Sair
              </S.BoxItemLink>
            )}
          </S.Box>
        </S.Content>
        <p>
          <b>Bem vindo :)</b>
          <span>
            {user ? user.givenName || user.email : 'Entre ou cadastre-se'}
          </span>
        </p>
      </S.Container>
    </>
  )
}

export default UserBox
