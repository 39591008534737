import type {
  DefaultTheme,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  ThemeProps,
} from 'styled-components'
import { css } from 'styled-components'

export const mediaMax = (
  cssParam:
    | FlattenInterpolation<ThemeProps<DefaultTheme>>
    | FlattenSimpleInterpolation,
  mediaMaxValue?: number
) => css`
  @media screen and (max-width: ${({ theme }) =>
      mediaMaxValue ? `${mediaMaxValue}px` : theme.sizes.mobile}) {
    ${cssParam}
  }
`
