import Icon from 'src/components/ui/Icon'

import { A } from './styles'

export const iconDicio = {
  facebook: <Icon name="facebook" size={30} />,
  blog: <Icon name="blog" size={30} />,
  instagram: <Icon name="instagram" size={30} />,
  twitter: <Icon name="twitter" size={30} />,
  tiktok: <Icon name="tiktok" size={30} />,
  youtube: <Icon name="youtube" size={30} />,
}

interface AnchorProps
  extends Omit<React.HTMLProps<HTMLAnchorElement>, 'ref' | 'children' | 'as'> {
  href: string
  icon: keyof typeof iconDicio
}

function Anchor({ href, icon, ...rest }: AnchorProps) {
  return (
    <A href={href} target="_blank" rel="noreferrer" {...rest}>
      {iconDicio[icon]}
    </A>
  )
}

export default Anchor
