import { useEffect, useState } from 'react'
import Cart from 'src/components/cart'
import Icon from 'src/components/ui/Icon'
import { useUI } from 'src/sdk/ui/Provider'
import { globalHistory } from '@reach/router'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import NewLogo from 'src/components/ui/Logo/NewLogo'

import { CustomLink } from '../../CustomLink'
import SearchInput from '../../../search/SearchInput'
import UserBox from '../UserBox'
import {
  Container,
  LogoBox,
  LogoBoxHome,
  RightWrapper,
  WishlistWrapper,
  IconsWrapper,
} from './styles'

interface HeaderCenterProps {
  isMicroHeader: boolean
  onMicroHeaderInputVisibilityChange?: (isSearchVisible: boolean) => void
}

const HeaderCenter: FCC<HeaderCenterProps> = ({
  isMicroHeader,
  onMicroHeaderInputVisibilityChange,
}) => {
  const { openNavbar } = useUI()
  const [isInputVisible, setIsInputVisible] = useState(false)
  const [isHome, setIsHome] = useState(false)
  const { isPhone } = useWindowDimensions()

  useEffect(() => {
    if (typeof window === 'undefined') return

    setTimeout(() => setIsHome(window?.location.pathname === '/'))

    return globalHistory.listen(({ action, location }) => {
      if (action === 'PUSH') {
        setIsHome(location.pathname === '/')
      }
    })
  }, [])

  useEffect(() => {
    onMicroHeaderInputVisibilityChange?.(isInputVisible)
  }, [isInputVisible, onMicroHeaderInputVisibilityChange])

  return (
    <Container>
      <SearchInput
        isMicroHeader={isMicroHeader}
        onMicroHeaderInputVisibilityChange={setIsInputVisible}
      />
      <RightWrapper>
        <button
          type="button"
          aria-label="Abrir menu lateral"
          onClick={openNavbar}
        >
          <Icon name="hamburger" width={25} height={17} />
        </button>

        <WishlistWrapper className="hide-on-desktop">
          <CustomLink to="/wishlist" aria-label="Wishlist">
            <Icon name="heart" width={27} height={23} />
          </CustomLink>
        </WishlistWrapper>

        {isHome && isPhone ? (
          <LogoBoxHome>
            <CustomLink className="m-1" aria-label="Miess Home" to="/">
              <NewLogo />
            </CustomLink>
          </LogoBoxHome>
        ) : (
          <LogoBox>
            <CustomLink className="m-1" aria-label="Miess Home" to="/">
              <NewLogo />
            </CustomLink>
          </LogoBox>
        )}

        <IconsWrapper>
          <UserBox />
          <WishlistWrapper className="hide-on-mobile">
            <CustomLink to="/wishlist" aria-label="Wishlist">
              <Icon name="heart" width={27} height={23} />
            </CustomLink>
          </WishlistWrapper>
          <Cart />
        </IconsWrapper>
      </RightWrapper>
    </Container>
  )
}

export default HeaderCenter
