import styled from 'styled-components'

export const CopyrightContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 82px;
  background-color: ${({ theme }) => theme.colors.grey_6};

  @media screen and (max-width: 940px) {
    height: 210px;
    justify-content: space-evenly;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.grey_6};
  }

  p {
    color: ${({ theme }) => theme.colors.white_1};
    font-size: 12px;
    line-height: 14px;
  }
  a {
    display: flex;
  }

  .logo {
    height: 40px;
  }

  @media screen and (max-width: 520px) {
    p {
      margin: 0 25px;
    }
  }
`

export const CopyrightLogos = styled.div`
  display: flex;

  margin-left: 30px;

  a {
    margin-right: 20px;
  }

  @media screen and (max-width: 940px) {
    margin: 0;
  }
`
