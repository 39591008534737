import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { DEFAULT_OPTIONS } from 'src/sdk/graphql/useQuery'
import useSWR from 'swr'

import { useAuth } from '../auth'
import type { WishlistContextData } from './types'

const WishlistContext = createContext<WishlistContextData>(
  {} as WishlistContextData
)

const WishlistProvider: FCC = ({ children }) => {
  const { user, signIn, isValidating: isUserValidating } = useAuth()
  const [forcedValidating, setForcedValidating] = useState(false)
  // const [productsIds, setProductsIds] = useState<string[]>(() => {
  //   // TODO Get from localstorage/masterdata
  //   return []
  // })
  const {
    data,
    mutate,
    isValidating: isSWRValidating,
  } = useSWR<number[]>(
    !!user?.email && '/api/wishlist',
    (url) => fetch(url).then((res) => res.json()),
    {
      ...DEFAULT_OPTIONS,
    }
  )

  const isValidating = useMemo(
    () => forcedValidating || isSWRValidating,
    [forcedValidating, isSWRValidating]
  )

  const productsIds = useMemo(
    () => (Array.isArray(data) ? data.map((entry) => String(entry)) : []),
    [data]
  )

  // useEffect(() => {
  //   if (!window) {
  //     return
  //   }

  //   const wishlist = localStorage.getItem('@Miess:wishlist')

  //   setProductsIds(wishlist ? JSON.parse(wishlist) : [])
  // }, [])

  const isWishlisted = useCallback(
    (id: string) => productsIds?.includes(id),
    [productsIds]
  )

  const addToWishlist = useCallback(
    async (id: string) => {
      if (!user?.email) {
        if (isUserValidating) {
          console.log('validating user...')

          setTimeout(() => {
            addToWishlist(id)
          }, 2000)

          return
        }

        signIn()

        return
      }

      if (isWishlisted(id) || isValidating) {
        return
      }

      console.log({ id })

      const optimisticData = data && [...data, Number(id)]

      mutate(
        async () => {
          setForcedValidating(true)
          await fetch('/api/wishlist', {
            method: 'post',
            body: JSON.stringify({ productId: id }),
            headers: {
              'Content-Type': 'application/json; charset=utf-8',
            },
          })

          await new Promise((resolve) => setTimeout(resolve, 1000))
          setForcedValidating(false)

          return optimisticData
        },
        {
          optimisticData,
        }
      )
    },
    [
      data,
      isUserValidating,
      isValidating,
      isWishlisted,
      mutate,
      signIn,
      user?.email,
    ]
  )

  const removeFromWishlist = useCallback(
    async (id: string) => {
      if (!user?.email) {
        signIn()

        return
      }

      if (!isWishlisted(id) || isValidating) {
        return
      }

      const optimisticData = data?.filter((prodId) => Number(id) !== prodId)

      // return a && [...a, Number(id)]
      mutate(
        async () => {
          setForcedValidating(true)

          await fetch(`/api/wishlist/${id}`, {
            method: 'delete',
          })

          await new Promise((resolve) => setTimeout(resolve, 1000))
          setForcedValidating(false)

          return optimisticData
        },
        {
          optimisticData,
        }
      )
    },
    [data, isValidating, isWishlisted, mutate, signIn, user?.email]
  )

  const toggleProduct = useCallback(
    async (id: string) => {
      if (isUserValidating) {
        setTimeout(() => {
          toggleProduct(id)
        }, 2000)

        return
      }

      if (!user?.email) {
        signIn()

        return
      }

      if (isValidating) {
        return
      }

      if (!isWishlisted(id)) {
        addToWishlist(id)
      } else {
        removeFromWishlist(id)
      }
    },
    [
      addToWishlist,
      isUserValidating,
      isValidating,
      isWishlisted,
      removeFromWishlist,
      signIn,
      user?.email,
    ]
  )

  return (
    <WishlistContext.Provider
      value={{
        productsIds,
        isWishlisted,
        addToWishlist,
        removeFromWishlist,
        toggleProduct,
        isValidating,
      }}
    >
      {children}
    </WishlistContext.Provider>
  )
}

function useWishlist(): WishlistContextData {
  const context = useContext(WishlistContext)

  if (!context) {
    throw new Error('useWishlist must be used within a WishlistProvider')
  }

  return context
}

export { WishlistProvider, useWishlist }
