import type { StoreConfigHeaderTopBarQuery } from '@generated/graphql'
import { graphql, useStaticQuery } from 'gatsby'
import { Image } from 'src/components/ui/Image'

import * as S from './styles'

const query = graphql`
  query StoreConfigHeaderTopBar {
    cmsStoreConfig {
      header {
        topBar {
          href
          alt
          desktop
          mobile
        }
      }
    }
  }
`

// const defaultOptions = {
//   options: {
//     fitIn: true,
//   },
//   layout: 'fullWidth' as Layout,
// }

const TopBar: FCC = () => {
  const { cmsStoreConfig } = useStaticQuery<StoreConfigHeaderTopBarQuery>(query)

  if (!cmsStoreConfig?.header?.topBar?.desktop) {
    return null
  }

  return (
    <S.TopBarContainer to={cmsStoreConfig.header.topBar.href}>
      <S.Figure>
        <Image
          src={cmsStoreConfig?.header?.topBar?.desktop ?? '/'}
          width={1920}
          height={40}
          alt={cmsStoreConfig?.header?.topBar?.alt ?? ''}
          className="hide-on-mobile"
          // media="(max-width: 60em)"
          // desktop={{
          //   baseUrl: cmsStoreConfig?.header?.topBar?.desktop ?? '/',
          //   sourceWidth: 1920,
          //   sourceHeight: 40,
          //   width: 1920,
          //   height: 40,
          // }}
          // mobile={{
          //   baseUrl: cmsStoreConfig?.header?.topBar?.mobile ?? '/',
          //   sourceWidth: 640,
          //   sourceHeight: 34,
          //   width: 640,
          //   height: 34,
          //   layout: 'fullWidth',
          // }}
          loading="eager"
          noFactors
          srcSet=""
        />
        <Image
          src={cmsStoreConfig?.header?.topBar?.mobile ?? '/'}
          width={640}
          height={34}
          alt={cmsStoreConfig?.header?.topBar?.alt ?? ''}
          className="hide-on-desktop"
          // media="(max-width: 60em)"
          // desktop={{
          //   baseUrl: cmsStoreConfig?.header?.topBar?.desktop ?? '/',
          //   sourceWidth: 1920,
          //   sourceHeight: 40,
          //   width: 1920,
          //   height: 40,
          // }}
          // mobile={{
          //   baseUrl: cmsStoreConfig?.header?.topBar?.mobile ?? '/',
          //   sourceWidth: 640,
          //   sourceHeight: 34,
          //   width: 640,
          //   height: 34,
          //   layout: 'fullWidth',
          // }}
          loading="eager"
          noFactors
          srcSet=""
        />
      </S.Figure>
    </S.TopBarContainer>
  )
}

export default TopBar
