import { mark } from 'src/sdk/tests/mark'
import { lazy, Suspense } from 'react'

import Copyright from './Copyright'
import Contact from './Contact'
import Social from './Social/Social'
import Institucional from './Institucional/Institucional'
import { FooterContainer, MainSection, MainContent } from './styles'

const PopUpNewsletter = lazy(() => import('./PopUpNewsletter'))
// const FixedButtons = lazy(() => import('./FixedButtons'))

function Footer() {
  return (
    <FooterContainer>
      <MainSection>
        <MainContent>
          <Contact />
          <Social />
          <Institucional />
        </MainContent>
      </MainSection>
      <Copyright />
      {typeof window !== 'undefined' && (
        <Suspense fallback={null}>
          <PopUpNewsletter />
          {/* <FixedButtons /> */}
        </Suspense>
      )}
    </FooterContainer>
  )
}

Footer.displayName = 'Footer'

export default mark(Footer)
