import styled, { css } from 'styled-components'

export const FooterContainer = styled.footer`
  width: 100%;
`

export const MainSection = styled.section`
  background-color: ${({ theme }) => theme.colors.grey_2};
  padding-top: 23px;
  padding-bottom: 28px;

  @media screen and (max-width: 1024px) {
    padding-bottom: 16px;
    padding-top: 0;
  }
`
export const MainContent = styled.div`
  max-width: 1220px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  font-family: 'Baloo 2';
  color: ${({ theme }) => theme.colors.white_1};

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  ${({ theme }) => css`
    @media screen and (max-width: calc(${theme.sizes.container} + (2 * ${theme
        .sizes.mobileMargin}))) {
      max-width: calc(100vw - (2 * ${theme.sizes.mobileMargin}));
      margin-left: ${theme.sizes.mobileMargin};
      margin-right: ${theme.sizes.mobileMargin};
    }
  `}/* @media screen and (max-width: 1260px) {
    max-width: calc(100% - 40px);
    margin-right: 20px;
    margin-left: 20px;
  } */
`
