import Icon from 'src/components/ui/Icon'

import { CallUsContainer, TextContainer } from './styles'

function CallUs() {
  return (
    <CallUsContainer>
      <Icon name="phone-footer" size={51} />
      <TextContainer>
        <p>
          <span className="white">Ligue para nós </span>
          <br />
          Fale conosco:
          <a href="tel://551148106810">
            <strong> (11) 4810-6810</strong>
          </a>
        </p>
        <p className="sub">
          <strong className="white">Horário de atendimento: </strong>
          <br />
          Segunda a Sexta das 8h às 18h
          <br />
          Aos sábados das 8h às 12h
        </p>
      </TextContainer>
    </CallUsContainer>
  )
}

export default CallUs
