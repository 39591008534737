import SlideOver from 'src/components/ui/SlideOver'
import { mediaMax } from 'src/styles/utils/mediaMax'
import styled, { css, keyframes } from 'styled-components'

export const StyledSlideOver = styled(SlideOver)`
  width: 75vw !important;
  max-width: 360px;
  position: relative;
  padding: 22px 16px;
  /* overflow-y: auto;
  overflow-x: none; */

  ${mediaMax(
    css`
      width: calc(100vw - 72px) !important;
    `,
    480
  )}

  background-color: ${({ theme }) => theme.colors.grey_2} !important;
`

export const Content = styled.div`
  overflow: auto;
  max-height: 100%;
  -webkit-appearance: none;
  padding-bottom: 70px;
`

export const LogoBox = styled.section`
  > a {
    width: 104px;
    height: 40px;
    display: flex;
    align-items: center;
    user-select: none;
    box-shadow: none !important;
  }

  margin-bottom: 24px;
`

export const LogoBoxHome = styled.h1`
  > a {
    width: 104px;
    height: 40px;
    display: flex;
    align-items: center;
    user-select: none;
    box-shadow: none !important;
  }

  margin-bottom: 24px;
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  40% {
    transform: translateX(0);
  }
  100% {
    opacity: 1;
  }
`

export const UserWrapper = styled.div`
  display: flex;
  color: #f0863f;
  font-size: 14px;
  font-weight: 400;
  animation: ${fadeIn} 300ms;

  .icon-user__container {
    width: 23px;
    height: 24px;
  }

  b {
    margin-left: 12px;
    margin-right: 15px;
    font-family: Baloo;
  }

  > a {
    display: flex;
    line-height: 16px;

    .icon-close2__container {
      width: 8px;
      height: 8px;
      display: flex;
      margin-left: 9px;
      margin-top: 3px;
    }

    > p {
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      color: #f0863f;
      font-size: 12px;
      font-weight: 400;
      width: 100%;

      line-height: 14px;

      > span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      > b {
        margin: 0;
        font-family: Baloo;
      }
    }
  }

  ${mediaMax(
    css`
      align-items: center;
      line-height: 15px;
    `,
    1024
  )}
`

export const CloseButton = styled.button`
  position: absolute;
  left: 100%;
  top: 0;

  width: 72px;
  height: 72px;
  background-color: #91153a !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;

  color: ${({ theme }) => theme.colors.orange_0};
`

export const HorizontalRow = styled.hr`
  width: 100%;
  height: 0px;
  margin: 20px 0;
  border: 1px solid rgba(233, 85, 74, 0.5);

  @media screen and (max-width: 375px) {
    margin: 22px 0;
  }
`

export const MenuList = styled.ul<{ baloo?: boolean }>`
  display: flex;
  flex-direction: column;
  animation: ${fadeIn} 300ms;

  ${({ baloo }) =>
    baloo &&
    css`
      font-family: Baloo;
    `}
`

export const MenuListItem = styled.li<{ highlight?: boolean; bold?: boolean }>`
  font-size: 16px;
  line-height: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ highlight, theme, bold }) =>
    css`
      color: ${highlight ? theme.colors.secondary_1 : '#ffffff'};
      ${bold && 'font-weight: bold;'}
    `};

  > a {
    display: flex;
  }

  & + & {
    margin-top: 22px;
  }
`

export const MenuListItemButton = styled.span`
  font-size: 25px;
  margin-right: 25px;
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: Baloo;
  font-size: 24px;
  line-height: 38px;

  color: #ffffff;

  animation: ${fadeIn} 300ms;

  .icon-chevron-left__container {
    margin-right: 16px;
  }
`

export const SubLinkList = styled.ul`
  display: flex;
  flex-direction: column;
  animation: ${fadeIn} 300ms;
`

export const SubLinkListItem = styled.li<{ highlight?: boolean }>`
  font-size: 18px;
  line-height: 50px;

  color: white;

  ${({ highlight, theme }) =>
    highlight &&
    css`
      font-weight: bold;
      color: ${theme.colors.orange_0};
    `}

  &:hover {
    color: ${({ theme }) => theme.colors.primary_0};
  }
  /* or 51px */

  /* & + & {
    margin-top: 22px;
  } */
`

export const FooterBoxList = styled.ul`
  display: flex;
  flex-direction: column;
  animation: ${fadeIn} 300ms;

  &:before {
    content: '';
    border: 1px solid rgba(233, 85, 74, 0.5);
    margin: 16px 0;
    width: 100%;
  }
`
export const FooterBoxListItem = styled.li`
  > a {
    display: flex;
  }
  font-family: Baloo;
  font-size: 14px;
  line-height: 13px;
  color: ${({ theme }) => theme.colors.orange_0};

  svg {
    margin-right: 12px;
  }
  & + & {
    margin-top: 15px;
  }
`
