import styled from 'styled-components'

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
  }
`

export const Institucional = styled.div`
  @media screen and (max-width: 1024px) {
    max-width: 100%;
  }
  ul {
    margin-top: 6px;
  }
  li > a {
    display: flex;
    font-size: 13px;
    margin-bottom: 5px;
    line-height: 150%;
  }

  ul > li:last-child {
    color: ${({ theme }) => theme.colors.secondary_1};
    font-weight: 700;
  }
`

export const Methods = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  @media (max-width: 1024px) {
    display: none;
  }

  span {
    font-size: 11px;
    margin-top: 10px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    width: 180px;
    margin-top: 6px;

    li {
      margin-right: 8px;
    }
  }

  ul > li:last-child {
    color: ${({ theme }) => theme.colors.secondary_1};
  }
`
