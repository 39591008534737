import styled from 'styled-components'

export const Payments = styled.div``
export const PaymentContainer = styled.div`
  @media (max-width: 940px) {
    ul > li:nth-child(7) {
      display: flex;
      flex-direction: column;
    }
  }
`
export const TermContainer = styled.div`
  display: none;
`
