import type {
  DefaultTheme,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  ThemeProps,
} from 'styled-components'
import { css } from 'styled-components'

export const mediaMin = (
  cssParam:
    | FlattenInterpolation<ThemeProps<DefaultTheme>>
    | FlattenSimpleInterpolation,
  mediaMinValue?: number
) => css`
  @media screen and (min-width: ${({ theme }) =>
      `${mediaMinValue ?? parseInt(theme.sizes.mobile, 10) + 1}px`}) {
    ${cssParam}
  }
`
