import { CustomLink } from 'src/components/common/CustomLink'

export const linkDicio = {
  PinkFriday: <CustomLink to="/pink-friday">Pink Friday</CustomLink>,
  DiaDosNamorados: (
    <CustomLink to="/dia-dos-namorados">Dia dos namorados</CustomLink>
  ),
  Natal: <CustomLink to="/natal">Natal</CustomLink>,
}

interface RightListProps {
  link: keyof typeof linkDicio
}

function RightList({ link }: RightListProps) {
  return <li>{linkDicio[link]}</li>
}

export default RightList
