import { css } from 'styled-components'

export const containerCenter = css`
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  ${({ theme }) => css`
    max-width: ${theme.sizes.container};

    @media screen and (max-width: calc(${theme.sizes.container} + (2 * ${theme
        .sizes.mobileMargin}))) {
      max-width: calc(100vw - (2 * ${theme.sizes.mobileMargin}));
      margin-left: ${theme.sizes.mobileMargin};
      margin-right: ${theme.sizes.mobileMargin};
    }
  `}
`
