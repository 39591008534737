import { EmblaSlide } from 'src/components/ui/Embla/styles'
import { mediaMax } from 'src/styles/utils/mediaMax'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  margin-bottom: 20px;
  position: relative;
  transition: 0.3s;
  width: 100%;

  ${mediaMax(css`
    margin-top: 10px;
    margin-bottom: 14px;
    a {
      font-size: 16px !important;
    }
  `)}

  ${EmblaSlide} {
    svg {
      margin-right: 5px;
      width: 17px;
      height: auto;
    }
    > a {
      color: #f0863f;
      font-size: 11px;
      display: flex;
      align-items: center;
      font-family: 'Baloo';
    }
  }
`

export const Content = styled.div`
  overflow: hidden;
`

export const Box = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  height: 37px;
  border-bottom: 0.8px solid #f0863f4d;
  padding: 0 5px;

  > a {
    color: #f0863f;
    font-size: 11px;
    display: flex;
    align-items: center;
    font-family: 'Baloo';
  }

  svg {
    margin-right: 5px;
  }
`

export const SkeletonContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
`
