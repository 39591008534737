import { css } from 'styled-components'

interface ShinyButtonProps {
  $width?: string
  $alpha?: string
  $transition?: string
  $disable?: boolean
}

export const shinyButton = ({
  $width = '60px',
  $alpha = '0.4',
  $transition = '500ms',
  $disable = false,
}: ShinyButtonProps) => css`
  ${$disable === true
    ? css`
        display: none;
      `
    : css`
        position: relative;
        overflow: hidden;

        &::before {
          position: absolute;
          content: '';
          top: 0;
          right: calc(100% + 11px);
          transform: skewX(-25deg);
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, ${$alpha}) 50%,
            rgba(255, 255, 255, 0) 100%
          );
          height: 100%;
          width: ${$width};
          cursor: pointer;
        }

        &:hover {
          &::before {
            right: -71px;
            transition: ${$transition} linear;
          }
        }
      `}
`
