import { CustomLink } from 'src/components/common/CustomLink'

import storeConfig from '../../../../../../../store.config'

const linkDicio = {
  MeusDados: (
    <CustomLink to={`${storeConfig.accountUrl}`} rel="nofollow">
      Meus Dados
    </CustomLink>
  ),
  MeusPedidos: (
    <CustomLink to={`${storeConfig.ordersUrl}`} rel="nofollow">
      Meus Pedidos
    </CustomLink>
  ),
  Faq: <CustomLink to="/institucionais/AntesCompra/faq">FAQ</CustomLink>,
  FaleConosco: (
    <CustomLink to="/institucionais/faleconosco">Fale Conosco</CustomLink>
  ),
}

interface MidListProps {
  link: keyof typeof linkDicio
}

function MidList({ link }: MidListProps) {
  return <li>{linkDicio[link]}</li>
}

export default MidList
