export const throttle = (callbackFn: () => void, limit: number) => {
  let wait = false

  return () => {
    if (wait) return
    callbackFn()
    wait = true
    setTimeout(() => {
      wait = false
    }, limit)
  }
}
