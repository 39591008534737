import styled from 'styled-components'

import { shinyButton } from '../../../../../../../styles/utils/shinnyButton'

export const Li = styled.li`
  ${shinyButton}

  display: flex;
  a {
    display: flex;
  }
`
