import { containerCenter } from 'src/styles/utils/containerCenter'
import { mediaMax } from 'src/styles/utils/mediaMax'
import styled, { css, keyframes } from 'styled-components'

import type {
  InfoLinkItemContainerProps,
  InfoLinkItemImageWrapperProps,
  InfoLinkItemListItemProps,
} from './types'

const menuIn = keyframes`
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
  }
`

const menuHoverIn = keyframes`
	from {
		height: 0px;
	}
	to {
		height: 9px;
	}
`

export const HoverPanel = styled.div`
  max-width: unset;
  display: none;
  position: absolute;
  padding-top: 35px;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  z-index: 4;
  max-height: 365px;
  width: 100%;
  border-radius: 0 0 20px 20px;
  box-shadow: 0px 3px 3px #cccccc;
  padding-bottom: 30px;
  -webkit-animation: ${menuIn} 300ms backwards;
  animation: ${menuIn} 300ms backwards;

  &.close {
    display: none !important;
  }
`

export const HoverPanelContent = styled.div`
  ${containerCenter}
  display: flex;
`

export const Container = styled.li<InfoLinkItemContainerProps>`
  height: 100%;
  display: flex;
  align-items: center;

  & + & {
    margin-left: 54px;
  }

  &:hover {
    > a {
      color: ${({ theme }) => theme.colors.secondary_1};
      &::after {
        display: flex;
      }
    }
    ${HoverPanel} {
      display: flex;

      ${mediaMax(css`
        display: none !important;
      `)}
    }
  }

  > a {
    color: #fff;
    position: relative;
    transition: color 0.3s;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: '';
      width: 100%;
      height: 9px;
      border-radius: 0px 0px 20px 20px;
      color: ${({ theme }) => theme.colors.secondary_1};
      background-color: ${({ theme }) => theme.colors.secondary_1};
      position: absolute;
      /* top: 30px; */
      top: 100%;
      left: 0;
      display: none;
      animation: ${menuHoverIn} 300ms backwards;
      z-index: 3;

      @media screen and (max-width: ${({ theme }) => theme.sizes.mobile}) {
        display: none !important;
      }
    }

    ${({ bold }) =>
      bold &&
      css`
        font-family: 'Baloo';
      `}
  }
`
export const ImageWrapper = styled.div<InfoLinkItemImageWrapperProps>`
  height: 100%;
`

export const ListWrapper = styled.div<{ hasLeftImage?: boolean }>`
  /* width: 100%; */
  flex: 1;
  display: flex;
  flex-direction: column;

  ${({ hasLeftImage }) =>
    hasLeftImage &&
    css`
      border-left: 1px solid #c4c4c4;
      padding-left: 30px;
      margin-left: 30px;
    `}
`

export const Title = styled.strong`
  font: 700 16px 'Baloo 2';
  color: #91153a;
  margin-bottom: 16px;
`

export const List = styled.ul`
  /* flex: 1; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 100%;
  width: 485px;
  /* align-content: space-between; */
  column-gap: 30px;
`

export const ListItem = styled.li<InfoLinkItemListItemProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 210%;

  color: #361328;

  ${({ highlighted }) =>
    highlighted &&
    css`
      font-weight: 700;
      text-decoration: underline;
    `}
`

export const MosaicRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 9px;
`
