import { emblaBreakpoints } from 'src/styles/utils/emblaBreakpoints'
import styled, { css } from 'styled-components'

import type { EmblaContainerProps } from './types'

export const EmblaContent = styled.div`
  display: flex;
`

export const EmblaSlide = styled.div`
  /* max-width: calc(100% / 8); */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

// export const emblaDraggableBreakpoints = (
//   breakpoints: EmblaBreakpoints,
//   slidesCount: number
// ) => {
//   const styles = Object.entries(breakpoints)
//     .map(([key, value]) =>
//       slidesCount <= value
//         ? `

//         @media screen and (min-width: ${key}px) {
//           &:before {
//             display: none;
//             content: '{
//               "draggable": false
//             }';
//           }
//         }
//       `
//         : ''
//     )
//     .join(' ')

//   return css`
//     ${styles}
//   `
// }

const emblaScrollBreakpoints = (props: EmblaContainerProps) => {
  const styles = Object.entries(props.breakpoints ?? {})
    .map(
      ([key, value]) => `
        @media screen and (min-width: ${key}px) {
          &:before {
            display: none;
            content: '{
              "slidesToScroll": ${value * (props.gridRows ?? 1)}
            }';
          }
        }
      `
    )
    .join(' ')

  return css`
    ${styles}
  `
}

// ${({ breakpoints, slidesCount, sliderPerView }) =>
// !!slidesCount &&
// (breakpoints
//   ? emblaDraggableBreakpoints(breakpoints, slidesCount)
//   : !!sliderPerView &&
//     emblaDraggableBreakpoints({ 1: sliderPerView }, slidesCount))}

export const EmblaContainer = styled.div<EmblaContainerProps>`
  overflow: hidden;
  width: 100%;

  /* ${(props) => props.fullSlide && emblaScrollBreakpoints(props)} */

  ${({ gridRows }) =>
    gridRows === 2 &&
    css`
      ${EmblaContent} {
        flex-wrap: wrap;
        flex-direction: column;
        height: 100%;
      }
    `}

  ${EmblaSlide} {
    max-width: 100%;
    /* flex: 0 0 calc(100% / ${({ sliderPerView }) => sliderPerView}); */
    min-width: calc(100% / ${({ sliderPerView }) => sliderPerView});

    ${({ breakpoints }) => breakpoints && emblaBreakpoints(breakpoints)}
  }
`
