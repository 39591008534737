import { useEffect, useState } from 'react'
import Icon from 'src/components/ui/Icon'
import { globalHistory } from '@reach/router'

import { ListContainer } from './styles'

interface ListProps {
  title: string
  span?: string
}

const List: FCC<ListProps> = ({ children, title, span }) => {
  const [style, setStyle] = useState(false)

  const toggleStyle = () => {
    setStyle(!style)
  }

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') setStyle(false)
    })
  }, [setStyle])

  return (
    <ListContainer open={style}>
      <button onClick={toggleStyle}>
        <h3>
          {title} <Icon name="arrowup" width={14} height={11} />
        </h3>
      </button>
      <div className={style ? 'visible' : 'dropdownContent'}>
        <span>{span}</span>
        <ul>{children}</ul>
      </div>
    </ListContainer>
  )
}

export default List
