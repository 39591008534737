import { Image } from 'src/components/ui/Image'
import Icon from 'src/components/ui/Icon'

import List from '../List'
import {
  RightContainer,
  TopContainer,
  MidContainer,
  BotContainer,
} from './styles'
import RightList from './RightList'
import PaymentMobile from '../PaymentMethods/PaymentMobile'
import SecurityItem from './SecurityList/SecurityItem'

function RightContent() {
  return (
    <RightContainer>
      <TopContainer>
        <List title="Fique por Dentro">
          <RightList link="PinkFriday" />
          <RightList link="DiaDosNamorados" />
          <RightList link="Natal" />
        </List>
      </TopContainer>
      <MidContainer>
        <PaymentMobile />
      </MidContainer>
      <BotContainer>
        <List title="Segurança">
          <SecurityItem
            href="https://secure.vtex.com/?an=miess"
            aria-label="VTEX Certified"
          >
            <Icon name="vtex-certified" width={51} height={36} />
          </SecurityItem>
          <SecurityItem
            href="https://letsencrypt.org/pt-br/"
            aria-label="Let's Encrypt"
          >
            <Icon name="encripty" width={46} height={36} />
          </SecurityItem>
          <SecurityItem
            href="https://www.reclameaqui.com.br/empresa/miess-moda-intima-e-sex-shop/"
            aria-label="Certificado Reclame Aqui"
          >
            <Image
              src="http://miess.vtexassets.com/arquivos/ids/237526/"
              width={200}
              height={104}
              style={{ height: 'auto' }}
              alt="Certificado Reclame Aqui"
              title="Certificado Reclame Aqui"
              className="icon"
              srcSet=""
              noFactors
              loading="lazy"
            />
          </SecurityItem>
        </List>
      </BotContainer>
    </RightContainer>
  )
}

export default RightContent
