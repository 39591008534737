import {
  createContext,
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { globalHistory } from '@reach/router'

import type { QuickViewContextData } from './types'

const QuickViewModal = lazy(
  () => import('../../components/product/QuickViewModal')
)

const QuickViewContext = createContext<QuickViewContextData>(
  {} as QuickViewContextData
)

const QuickViewProvider: FCC = ({ children }) => {
  const [skuId, setSkuId] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpenModal = useCallback(() => setIsModalOpen(true), [])

  const handleCloseModal = useCallback(() => setIsModalOpen(false), [])

  const open = useCallback(
    (sku: string) => {
      setSkuId(sku)
      handleOpenModal()
    },
    [handleOpenModal]
  )

  useEffect(() => {
    globalHistory.listen(({ action }) => {
      if (action === 'PUSH') setIsModalOpen(false)
    })
  }, [setIsModalOpen])

  useEffect(() => {
    if (isModalOpen) {
      document.documentElement.style.overflowY = 'hidden'

      return
    }

    document.documentElement.style.overflowY = 'auto'
  }, [isModalOpen])

  return (
    <QuickViewContext.Provider value={{ open, close: handleCloseModal }}>
      {isModalOpen && (
        <Suspense fallback={null}>
          <QuickViewModal onClose={handleCloseModal} skuId={skuId} />
        </Suspense>
      )}
      {children}
    </QuickViewContext.Provider>
  )
}

function useQuickView(): QuickViewContextData {
  const context = useContext(QuickViewContext)

  if (!context) {
    throw new Error('useQuickView must be used within a QuickViewProvider')
  }

  return context
}

export { QuickViewProvider, useQuickView }
