import { mediaMax } from 'src/styles/utils/mediaMax'
import styled, { css } from 'styled-components'

import type { ProgressProps } from './types'

export const Container = styled.div`
  width: 100%;
  height: 40px;
  min-height: 40px;
  background-color: ${({ theme }) => theme.colors.secondary_0};
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;

  ${mediaMax(css`
    text-align: center;
  `)}

  > span {
    z-index: 1;
    font-weight: 700;
    font-size: 14px;
    width: 100%;
    max-width: 1220px;
    margin: 0 auto;
    color: #361328;
  }
`

export const Progress = styled.div<ProgressProps>`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.secondary_1};
  position: absolute;
  left: 0;
  width: ${({ value = 0 }) => `${value}%`};
  transition: 2s;

  &::after {
    ${({ value = 0 }) =>
      value <= 0 &&
      css`
        display: none;
      `};
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 40px 40px 0 0;
    border-color: ${({ theme }) => theme.colors.secondary_1} transparent
      transparent transparent;
    position: absolute;
    right: -40px;
    top: 0;
  }
`
