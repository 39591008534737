import { Image } from 'src/components/ui/Image'

import { MidContainer, MidListContainer } from './styles'
import List from '../List'
import HelpList from './MidList'

function MidContent() {
  return (
    <MidContainer>
      <MidListContainer>
        <List title="Ajuda">
          <HelpList link="MeusDados" />
          <HelpList link="MeusPedidos" />
          <HelpList link="Faq" />
          <HelpList link="FaleConosco" />
        </List>
      </MidListContainer>
      <MidListContainer>
        <List title="Certificações">
          <Image
            src="http://miess.vtexassets.com/arquivos/selo-anvisa.png"
            width={287}
            height={243}
            style={{ height: 'auto', width: '80px' }}
            alt="Certificado Anvisa"
            title="Certificado Anvisa"
            className="icon"
            srcSet=""
            noFactors
            loading="lazy"
          />
        </List>
      </MidListContainer>
    </MidContainer>
  )
}

export default MidContent
