export const theme = {
  colors: {
    // Colors from Figma

    white_1: '#FFF',

    grey_1: '#000000',
    grey_2: '#361328',

    grey_3: '#818181',
    grey_4: '#E5E5E5',
    grey_5: '#F8F8F8',
    grey_6: '#290e1e',
    grey_7: '#c4c4c4',
    grey_8: '#f1f2f4',

    white: '#FFFFFF',

    primary_0: '#91153A',
    primary_1: '#6A0F2A',

    secondary_0: '#FFE2D7',

    secondary_1: '#F5B9A3',

    orange_0: '#F0863F',
    orange_1: '#E9554A',

    purple_0: '#D2A1F0',
    purple_1: '#7E00CC',

    danger_0: '#EA1D25',

    success_0: '#7FBD61',
  },

  sizes: {
    container: '1220px',
    mobile: '1024px',
    mobileMargin: '16px',
  },
} as const
