exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-[slug]-p-tsx": () => import("./../../../src/pages/[slug]/p.tsx" /* webpackChunkName: "component---src-pages-[slug]-p-tsx" */),
  "component---src-pages-account-beta-tsx": () => import("./../../../src/pages/account-beta.tsx" /* webpackChunkName: "component---src-pages-account-beta-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-black-friday-tsx": () => import("./../../../src/pages/black-friday.tsx" /* webpackChunkName: "component---src-pages-black-friday-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-compre-por-selecao-tsx": () => import("./../../../src/pages/Compre-por-selecao.tsx" /* webpackChunkName: "component---src-pages-compre-por-selecao-tsx" */),
  "component---src-pages-debug-tsx": () => import("./../../../src/pages/debug.tsx" /* webpackChunkName: "component---src-pages-debug-tsx" */),
  "component---src-pages-dia-dos-namorados-old-tsx": () => import("./../../../src/pages/dia-dos-namorados-old.tsx" /* webpackChunkName: "component---src-pages-dia-dos-namorados-old-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-institucionais-antes-compra-faq-old-tsx": () => import("./../../../src/pages/institucionais/AntesCompra/faq-old.tsx" /* webpackChunkName: "component---src-pages-institucionais-antes-compra-faq-old-tsx" */),
  "component---src-pages-institucionais-antes-compra-lgpd-tsx": () => import("./../../../src/pages/institucionais/AntesCompra/lgpd.tsx" /* webpackChunkName: "component---src-pages-institucionais-antes-compra-lgpd-tsx" */),
  "component---src-pages-institucionais-antes-compra-nossas-lojas-tsx": () => import("./../../../src/pages/institucionais/AntesCompra/NossasLojas.tsx" /* webpackChunkName: "component---src-pages-institucionais-antes-compra-nossas-lojas-tsx" */),
  "component---src-pages-institucionais-antes-compra-promocoes-tsx": () => import("./../../../src/pages/institucionais/AntesCompra/promocoes.tsx" /* webpackChunkName: "component---src-pages-institucionais-antes-compra-promocoes-tsx" */),
  "component---src-pages-institucionais-faleconosco-tsx": () => import("./../../../src/pages/institucionais/faleconosco.tsx" /* webpackChunkName: "component---src-pages-institucionais-faleconosco-tsx" */),
  "component---src-pages-institucionais-index-tsx": () => import("./../../../src/pages/institucionais/index.tsx" /* webpackChunkName: "component---src-pages-institucionais-index-tsx" */),
  "component---src-pages-institucionais-links-uteis-iniciativa-covid-old-tsx": () => import("./../../../src/pages/institucionais/linksUteis/iniciativaCovid_old.tsx" /* webpackChunkName: "component---src-pages-institucionais-links-uteis-iniciativa-covid-old-tsx" */),
  "component---src-pages-live-commerce-old-tsx": () => import("./../../../src/pages/live-commerce-old.tsx" /* webpackChunkName: "component---src-pages-live-commerce-old-tsx" */),
  "component---src-pages-login-old-tsx": () => import("./../../../src/pages/login-old.tsx" /* webpackChunkName: "component---src-pages-login-old-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-natal-tsx": () => import("./../../../src/pages/natal.tsx" /* webpackChunkName: "component---src-pages-natal-tsx" */),
  "component---src-pages-outubro-rosa-tsx": () => import("./../../../src/pages/OutubroRosa.tsx" /* webpackChunkName: "component---src-pages-outubro-rosa-tsx" */),
  "component---src-pages-pink-friday-tsx": () => import("./../../../src/pages/pink-friday.tsx" /* webpackChunkName: "component---src-pages-pink-friday-tsx" */),
  "component---src-pages-premio-reclame-aqui-2021-tsx": () => import("./../../../src/pages/Premio-ReclameAqui2021.tsx" /* webpackChunkName: "component---src-pages-premio-reclame-aqui-2021-tsx" */),
  "component---src-pages-revenda-catalogo-tsx": () => import("./../../../src/pages/revenda-catalogo.tsx" /* webpackChunkName: "component---src-pages-revenda-catalogo-tsx" */),
  "component---src-pages-s-tsx": () => import("./../../../src/pages/s.tsx" /* webpackChunkName: "component---src-pages-s-tsx" */),
  "component---src-pages-slug-tsx": () => import("./../../../src/pages/[...slug].tsx" /* webpackChunkName: "component---src-pages-slug-tsx" */),
  "component---src-pages-wishlist-tsx": () => import("./../../../src/pages/wishlist.tsx" /* webpackChunkName: "component---src-pages-wishlist-tsx" */),
  "component---src-templates-custom-plp-tsx": () => import("./../../../src/templates/customPlp.tsx" /* webpackChunkName: "component---src-templates-custom-plp-tsx" */),
  "component---src-templates-institutional-tsx": () => import("./../../../src/templates/institutional.tsx" /* webpackChunkName: "component---src-templates-institutional-tsx" */)
}

