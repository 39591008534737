import Icon from 'src/components/ui/Icon'
import { globalHistory } from '@reach/router'
import { useEffect, useState } from 'react'

import { CopyrightContainer, CopyrightLogos } from './styles'
import { CustomLink } from '../../CustomLink'

function Copyright() {
  const [isHome, setIsHome] = useState(false)

  useEffect(() => {
    if (typeof window === 'undefined') return

    setTimeout(() => setIsHome(window?.location.pathname === '/'))

    return globalHistory.listen(({ action, location }) => {
      if (action === 'PUSH') {
        setIsHome(location.pathname === '/')
      }
    })
  }, [])

  return (
    <CopyrightContainer>
      <CustomLink to="/" title="miess logo sex shop" className="link">
        <Icon name="miess-footer" width={110} height={41} margin="0 43px 0 0" />
      </CustomLink>
      <p>
        Miess. 2008 - 2022 - Todos os direitos reservados.
        <br />
        Rua Cavour, nº708, Vila Prudente - CEP: 03136-010 - SAC (11) 4810-6810 -
        sac@miess.com.br
        <br />
        Razão Social: MIESS MODA INTIMA ECOMMERCE LTDA - ME - CNPJ:
        10.365.271/0001-02
      </p>
      {isHome && (
        <CopyrightLogos>
          <a
            href="https://www.agenciaeplus.com.br"
            target="_blank"
            title="Agência e-Plus"
            rel="noreferrer"
          >
            <Icon name="eplus-logo" width={112} height={22} />
          </a>
          <a
            href="https://www.vtex.com/pt-br/"
            target="_blank"
            title="VTEX"
            rel="noreferrer"
          >
            <Icon name="vtex-logo" width={56} height={21} />
          </a>
        </CopyrightLogos>
      )}
    </CopyrightContainer>
  )
}

export default Copyright
