import type { StoreConfigHeaderTopBarInformationMobileQuery } from '@generated/graphql'
import { stringToSanitizedHtml } from 'src/utils/stringToSanitizedHtml'
import { graphql, useStaticQuery } from 'gatsby'
import Icon from 'src/components/ui/Icon'
import Embla from 'src/components/ui/Embla'

import * as S from './styles'
import { CustomLink } from '../../CustomLink'

const query = graphql`
  query StoreConfigHeaderTopBarInformationMobile {
    cmsStoreConfig {
      header {
        headerInformation {
          information {
            icon
            link
            text
          }
        }
      }
    }
  }
`

const HeaderTopMobile: FCC = () => {
  const { cmsStoreConfig } =
    useStaticQuery<StoreConfigHeaderTopBarInformationMobileQuery>(query)

  const listInformation = cmsStoreConfig?.header?.headerInformation?.information

  if (!listInformation?.length) {
    return <p>Lista de informações vazia, cadastre na plataforma.</p>
  }

  return (
    <S.Container className="top-bar">
      {/* {loading && (
        <S.SkeletonContainer>
          <Loading type="dots" color="orange_0" />
        </S.SkeletonContainer>
      )} */}
      <div className="top-bar--desktop">
        {listInformation.map(({ link, text, icon }: any) => (
          <CustomLink key={text} to={link}>
            {stringToSanitizedHtml(icon)}
            {text}
          </CustomLink>
        ))}
      </div>
      <Embla
        sliderPerView={5}
        navigation={{
          component: (
            <Icon name="triangle-arrow-right" width={10} height={13} />
          ),
        }}
        loop
        autoplay={{
          delay: 5000,
        }}
        breakpoints={{
          660: 2,
          1: 1,
        }}
      >
        {listInformation.map(({ link, text, icon }: any) => (
          <CustomLink key={text} to={link}>
            {stringToSanitizedHtml(icon)}
            {text}
          </CustomLink>
        ))}
      </Embla>
    </S.Container>
  )
}

export default HeaderTopMobile
