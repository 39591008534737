import {
  Children,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import { toArray } from 'src/typings/toArray'
import { toClassname } from 'src/utils/toClassname'

import * as S from './styles'
import type { EmblaProps } from './types'
import EmblaPagination from './EmblaPagination'
import Icon from '../Icon'

const Embla: FCC<EmblaProps> = (props) => {
  const {
    children,
    sliderPerView,
    breakpoints,
    paginationRef,
    navigation,
    onSlideChange,
    onEmbla,
    autoplay,
    gridRows,
    fullSlide,
    debug,
    originalBreakpoints,
    ...rest
  } = props

  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([])
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)

  const autoplayInstance = useRef(
    Autoplay(
      {
        active: !!autoplay,
        delay: autoplay?.delay ?? 5000,
        stopOnInteraction: !!autoplay?.stopOnInteraction,
        playOnInit: false,
      }
      // (emblaRoot) => emblaRoot.parentElement
    )
  )

  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      slidesToScroll: 1,
      draggable: true,
      align: 'start',
      containScroll: 'trimSnaps',
      breakpoints: originalBreakpoints,
      ...rest,
    },
    [autoplayInstance.current]
  )

  const onSelect = useCallback(() => {
    if (!emblaApi) return
    const activeIndex = emblaApi.selectedScrollSnap()

    setSelectedIndex(activeIndex)
    onSlideChange?.(activeIndex)

    // if (!rest.loop) {
    setPrevBtnEnabled(emblaApi.canScrollPrev())
    setNextBtnEnabled(emblaApi.canScrollNext())
    // }
  }, [emblaApi, onSlideChange])

  const notEnoughToLoop = useMemo(
    () => !emblaApi?.internalEngine().slideLooper.canLoop(),
    [emblaApi]
  )

  useEffect(() => {
    if (!emblaApi) {
      return
    }

    emblaApi.reInit({
      draggable: emblaApi.internalEngine().options.loop
        ? !notEnoughToLoop
        : true,
    })
    onSelect()
    emblaApi.on('select', onSelect)
    // onEmbla?.(emblaApi)
    setScrollSnaps(emblaApi.scrollSnapList())

    debug &&
      console.log({
        notEnoughToLoop,
        activeIndex: emblaApi.selectedScrollSnap(),
      })

    // autoplayInstance.reset()
    if (
      autoplay &&
      emblaApi &&
      emblaApi.internalEngine()
      // !notEnoughToLoop
      // (emblaApi.scrollSnapList().length > sliderPerView || !notEnoughToLoop)
    ) {
      autoplayInstance.current.play()
    }

    return () => {
      emblaApi.off('select', onSelect)
    }
  }, [
    emblaApi,
    children,
    onSelect,
    onEmbla,
    notEnoughToLoop,
    debug,
    autoplay,
    autoplayInstance,
  ])

  useEffect(() => {
    if (!emblaApi) {
      return
    }

    onEmbla?.(emblaApi)
  }, [emblaApi, onEmbla, children])

  const scrollPrev = useCallback(() => emblaApi?.scrollPrev(), [emblaApi])
  const scrollNext = useCallback(() => emblaApi?.scrollNext(), [emblaApi])
  const scrollTo = useCallback(
    (index: number) => emblaApi?.scrollTo(index),
    [emblaApi]
  )

  const parsedRefs = useMemo(() => toArray(paginationRef), [paginationRef])

  const getSlideClassname = useCallback(
    (index: number) => {
      const classes = [
        'embla__slide',
        index === selectedIndex && 'embla__slide__active',
        index === 0 && 'embla__slide__first',
        index === scrollSnaps.length - 1 && 'embla__slide__last',
        index === selectedIndex - 1 && 'embla__slide__prev',
        index === selectedIndex + 1 && 'embla__slide__next',
      ]

      return toClassname(classes)
    },
    [scrollSnaps.length, selectedIndex]
  )

  return (
    <>
      <S.EmblaContainer
        ref={emblaRef}
        sliderPerView={sliderPerView}
        breakpoints={breakpoints}
        gridRows={gridRows}
        fullSlide={fullSlide}
        className="embla__container"
      >
        <S.EmblaContent className="embla__content">
          {Children.map(children, (Slide, index) => (
            <S.EmblaSlide className={getSlideClassname(index)}>
              {Slide}
            </S.EmblaSlide>
          ))}
        </S.EmblaContent>
      </S.EmblaContainer>
      {navigation && (
        <>
          <button
            type="button"
            className={`swiper-arrow prev-arrow ${
              !prevBtnEnabled ? 'swiper-button-disabled' : ''
            } ${
              typeof navigation !== 'boolean' && navigation.circle
                ? 'circle'
                : ''
            }`}
            onClick={scrollPrev}
            aria-label="Slide Anterior"
          >
            {(navigation as any).component ?? <Icon name="arrow" />}
          </button>
          <button
            type="button"
            className={`swiper-arrow next-arrow ${
              !nextBtnEnabled ? 'swiper-button-disabled' : ''
            } ${
              typeof navigation !== 'boolean' && navigation.circle
                ? 'circle'
                : ''
            }`}
            onClick={scrollNext}
            aria-label="Próximo Slide"
          >
            {(navigation as any).component ?? <Icon name="arrow" />}
          </button>
        </>
      )}
      {!!parsedRefs.length &&
        parsedRefs.map((pagRef, idx) =>
          pagRef.current ? (
            <EmblaPagination key={idx} element={pagRef.current}>
              {scrollSnaps.map((_, index) => (
                <button
                  className={`swiper-pagination-bullet ${
                    index === selectedIndex
                      ? 'swiper-pagination-bullet-active'
                      : ''
                  }`}
                  key={index}
                  onClick={() => scrollTo(index)}
                  type="button"
                  aria-label={`Ir para slide ${index + 1}`}
                />
              ))}
            </EmblaPagination>
          ) : null
        )}
    </>
  )
}

export default Embla
