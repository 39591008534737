import styled, { css } from 'styled-components'

export const ListContainer = styled.div<{ open: boolean }>`
  max-width: 100%;

  h3 {
    color: ${({ theme }) => theme.colors.white_1};
  }
  .icon-arrowup__container {
    display: none;
  }

  span {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 1024px) {
    padding: 12px 0;
    border-top: 1px solid ${({ theme }) => theme.colors.secondary_1};

    button {
      width: 100%;

      h3 {
        display: flex;
        justify-content: space-between;

        .icon-arrowup__container {
          width: 10px;
          display: inline-flex;
          transition: transform 0.2s;
        }
      }
      ${({ open }) =>
        open &&
        css`
          .icon-arrowup__container {
            transform: rotate(180deg);
          }
        `}
    }

    .dropdownContent {
      width: 100%;
      max-height: 0px;
      overflow: hidden;
      opacity: 0;
      visibility: hidden;
      transition: all 0.5s ease;
      margin: 0;
      margin-left: -16px;
    }
    .visible {
      transition: all 0.5s ease;
      max-height: 200px;
      opacity: 1;
      visibility: visible;
      margin-left: 0;
    }

    ul {
      font-family: 'Baloo 2';

      justify-content: space-evenly;
      li:last-child {
        font-weight: 700;
      }
    }
  }
`

export const TesteContainer = styled.div`
  display: flex;
  @media screen and (max-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }
`
