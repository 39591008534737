import { containerCenter } from 'src/styles/utils/containerCenter'
import { mediaMax } from 'src/styles/utils/mediaMax'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  height: 40px;
  background: #91153a;
  position: relative;

  ${mediaMax(
    css`
      display: none;
    `
  )}
`

export const Nav = styled.nav`
  height: 100%;
`

export const LinkNavList = styled.ul`
  ${containerCenter}
  display: flex;
  align-items: center;
  height: 100%;
`
