import { mediaMax } from 'src/styles/utils/mediaMax'
import styled, { css } from 'styled-components'

import { CustomLink } from '../../CustomLink'

export const TopBarContainer = styled(CustomLink)`
  height: 40px;
  display: flex;
  ${mediaMax(
    css`
      height: 19px;
      min-height: 19px;
      }
    `,
    640
  )}
`

export const Figure = styled.figure`
  ${mediaMax(
    css`
      img.hide-on-desktop {
        width: 100%;
        height: auto;
      }
    `,
    640
  )}

  img {
    margin: 0 auto;
  }
`
