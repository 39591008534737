import Icon from 'src/components/ui/Icon'

import { SocialContainer, MediaContainer } from './styles'
import Anchor from './Anchor'

function Social() {
  return (
    <SocialContainer>
      <Icon name="miess-red" width={68} height={66} />
      <p>
        <strong>Muito prazer!</strong> Somos a Miess Sexshop e Lingerie, uma
        loja virtual do mercado sexshop criada para comercializar produtos
        eróticos de alta qualidade e com preços competitivos.Certamente, uma de
        nossas maiores missões é levar a homens e mulheres mais prazer no sexo,
        maior autoconhecimento sobre seu próprio corpo e sua sexualidade. Além
        disso, nós, da Miess Sexshop e Lingerie temos o compromisso de formar
        parceiros e colaboradores especialistas na revenda sexshop.Assim,
        desejamos melhorar a vida financeira de todos com a geração de renda
        extra a partir da revenda de artigos sexuais inovadores..
      </p>

      <MediaContainer>
        <Anchor
          aria-label="YouTube"
          icon="youtube"
          href="https://www.youtube.com/channel/UCSrRELkt-IX8vw9CyYcr4jw/videos"
        />
        <Anchor
          aria-label="Twitter"
          icon="twitter"
          href="https://twitter.com/miessmodaintima"
        />
        <Anchor
          aria-label="Facebook"
          icon="facebook"
          href="https://www.facebook.com/miessloja"
        />
        <Anchor
          aria-label="TikTok"
          icon="tiktok"
          href="https://www.tiktok.com/@miessmodaintima/"
        />
        <Anchor
          aria-label="Instagram"
          icon="instagram"
          href="https://www.instagram.com/miessmodaintima/"
        />
        <Anchor
          aria-label="Blog"
          icon="blog"
          href="https://blog.miess.com.br/"
        />
      </MediaContainer>
    </SocialContainer>
  )
}

export default Social
