import { useState } from 'react'
import Icon from 'src/components/ui/Icon'

import PaymentItem from '../../LeftContent/PaymentList'
import {
  PaymentContainer,
  PaymentDropDown,
  Payments,
  PaymentsList,
} from './styles'

function PaymentMobile() {
  const [style, setStyle] = useState(false)

  const toggleStyle = () => {
    style ? setStyle(false) : setStyle(true)
  }

  return (
    <PaymentContainer open={style}>
      <button onClick={toggleStyle}>
        <h3>Métodos de pagamento</h3>
        <Icon name="arrowup" width={14} height={11} />
      </button>
      <PaymentDropDown>
        <Payments className={style ? 'visible' : 'dropdownContent'}>
          <PaymentsList>
            <PaymentItem icon="Boleto" />
            <PaymentItem icon="Brasil" />
            <PaymentItem icon="Santander" />
            <PaymentItem icon="Caixa" />
            <PaymentItem icon="Bradesco" />
            <PaymentItem icon="Itau" />
            <PaymentItem icon="Pix" />
            <PaymentItem icon="Master" />
            <PaymentItem icon="Visa" />
            <PaymentItem icon="Elo" />
            <PaymentItem icon="PayPal" />
          </PaymentsList>
        </Payments>
      </PaymentDropDown>
    </PaymentContainer>
  )
}

export default PaymentMobile
