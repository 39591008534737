import styled from 'styled-components'

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  align-items: center;

  .hide-on-desktop {
    visibility: hidden;
    margin-bottom: 15px;
  }

  @media screen and (max-width: 1024px) {
    align-items: flex-start;

    .hide-on-desktop {
      visibility: visible;
    }
  }

  hr {
    color: ${({ theme }) => theme.colors.secondary_1};
    width: 100%;
    margin: 20px 0;
  }

  p {
    color: ${({ theme }) => theme.colors.secondary_1};
  }
`
