import styled, { css, keyframes } from 'styled-components'

import type { RootCategoryListItemProps } from './types'

export const Container = styled.nav<{ isInvisible?: boolean }>`
  height: 63px;
  display: flex;
  color: ${({ theme }) => theme.colors.grey_2};

  opacity: 1;
  visibility: visible;

  transition: all 0.2s;

  ${({ isInvisible }) =>
    isInvisible &&
    css`
      opacity: 0;
      visibility: hidden;
    `}
`

export const RootCategoryList = styled.ul<{ full?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 680px;
  height: 100%;
  width: 100%;

  ${({ full }) =>
    full &&
    css`
      width: 100%;
    `}
`

const menuHoverIn = keyframes`
	from {
		height: 0px;
	}
	to {
		height: 9px;
	}
`

const menuIn = keyframes`
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
  }
`

export const RootCategoryListItem = styled.li<RootCategoryListItemProps>`
  display: flex;
  align-items: center;
  height: 100%;

  > a {
    font-size: 18px;
    color: ${({ isHighlighted, theme }) =>
      isHighlighted ? theme.colors.secondary_1 : 'white'};
    transition: 300ms;
    position: relative;
    z-index: 4;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Baloo';

    &::after {
      content: '';
      width: 100%;
      height: 9px;
      border-radius: 0px 0px 20px 20px;
      color: ${({ theme }) => theme.colors.secondary_1};
      background-color: ${({ theme }) => theme.colors.secondary_1};
      position: absolute;
      top: 63px;
      left: 0;
      display: none;
      animation: ${menuHoverIn} 300ms backwards;

      @media screen and (max-width: ${({ theme }) => theme.sizes.mobile}) {
        display: none !important;
      }
    }
  }

  &:hover {
    > a {
      color: ${({ theme }) => theme.colors.secondary_1};

      @media screen and (max-width: ${({ theme }) => theme.sizes.mobile}) {
        color: white;
      }

      &::after {
        display: flex;
      }
    }

    .list-container {
      display: flex;
    }
  }
`

export const CategoryListContainer = styled.div`
  display: none;
  max-height: 470px;
  position: absolute;
  padding-top: 34px;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  width: 100%;
  border-radius: 0 0 20px 20px;
  box-shadow: 0px 3px 3px #cccccc;
  padding-left: 131px;
  padding-bottom: 36px;
  padding-right: 22px;
  -webkit-animation: ${menuIn} 300ms backwards;
  animation: ${menuIn} 300ms backwards;
  z-index: 4;

  &.close {
    display: none !important;
  }

  > ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-height: 100%;
    align-content: space-between;

    > li {
      width: fit-content;
      font-weight: 400;
      font-size: 13px;
      margin-bottom: 16px;
      line-height: 14px;

      @media screen and (max-width: ${({ theme }) => theme.sizes.mobile}) {
        color: white !important;
        margin-bottom: 0 !important;
      }

      &:hover {
        color: ${({ theme }) => theme.colors.primary_0};
        text-shadow: 0px 0px 1px rgb(145, 21, 58);
      }
    }
  }
  .see-all {
    a {
      color: ${({ theme }) => theme.colors.grey_2};
      font-weight: 700;
      text-decoration: underline;
    }
  }
`

export const ImageWrapper = styled.figure`
  margin-left: 215px;
  display: flex;
  min-width: 300px;
`
