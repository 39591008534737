import styled from 'styled-components'

export const EmailContainer = styled.div`
  display: flex;
  align-items: center;
  width: 240px;

  p {
    font-size: 13px;
    margin-left: 15px;
  }
  strong {
    color: ${({ theme }) => theme.colors.white_1};
  }

  span {
    font-weight: 700;
  }
`
