import { mediaMax } from 'src/styles/utils/mediaMax'
import { mediaMin } from 'src/styles/utils/mediaMin'
import styled, { css, keyframes } from 'styled-components'

import type { SearchButtonProps } from './types'

export const InputWrapper = styled.div`
  position: relative;
  background-color: white;
  display: flex;
  flex: 1;
  height: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`

export const Input = styled.input<{
  onFocus: () => void
  name: string
}>`
  height: 100%;
  padding: 0;
  border: none;
  padding-left: 11px;
  width: 100%;
  background-color: transparent;

  &::placeholder {
    color: #c4c4c4;
  }
`

export const InputSelectWrapper = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  height: 100%;

  .select__indicator {
    position: absolute;
    top: 50%;
    right: 5px;

    transform: translateY(-50%);
  }
`

export const InputSelectFallback = styled.div`
  min-width: 78px;
  z-index: 5;
  height: 100%;

  background-color: ${({ theme }) => theme.colors.secondary_1};
  border: none;

  border-radius: 5px 0 0 5px;
  height: 100%;
  cursor: pointer;
  gap: 4px;
  padding-left: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  ${mediaMax(
    css`
      z-index: 5;
    `,
    1023
  )}

  span {
    font-size: 12px;
  }

  > div {
    padding: 0 10px 0 4px;
    margin-left: 4px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* .icon__container {
  } */
`

export const Container = styled.form<{
  isInputFocused?: boolean
  isMicroHeader?: boolean
  isInvisible?: boolean
}>`
  position: relative;
  display: flex;
  align-items: center;
  max-height: 42px;
  height: 100%;
  min-width: 674px;
  display: flex;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.grey_2};

  opacity: 1;
  visibility: visible;

  transition: all 0.2s;

  /* .select {
    /* max-width: 78px;
    min-width: 78px;
    z-index: 5;
    height: 100%;

    ${mediaMax(
    css`
      z-index: 5;
    `,
    1023
  )}

    &.mobile {
      .select__control {
        border-radius: 5px;
      }
    }

    &__menu {
      margin: 0;
      width: 228px;
      font-size: 12px;
      line-height: 14px;

      &-list {
        &::-webkit-scrollbar {
          width: 12px; /* width of the entire scrollbar
        }

        &::-webkit-scrollbar-track {
          background: ${({ theme }) => theme.colors.secondary_1};
        }

        &::-webkit-scrollbar-thumb {
          background-color: ${({ theme }) => theme.colors.primary_0};
          border-radius: 20px; /* roundness of the scroll thumb */
  /* border: 3px solid orange;
        }
      }
    }

    &__option {
      height: 38px;
      min-height: 38px;
      padding-left: 11px;
      display: flex;
      align-items: center;
      font-size: 12px;
      transition: 300ms;
      cursor: pointer;

      &:not(:first-child) {
        border-top: 1px solid #e5e5e5;
      }

      &--is-focused {
        background-color: ${({ theme }) => theme.colors.grey_4};
      }

      &--is-selected {
        font-weight: bold;
        background-color: white;
        color: inherit;
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__control {
      background-color: ${({ theme }) => theme.colors.secondary_1};
      border: none;

      border-radius: 5px 0 0 5px;
      height: 100%;
      cursor: pointer;
    }

    &__value-container {
      font-size: 12px;

      /* input {
        display: none;
      }
    }

    &__indicator {
      padding: 0 10px 0 4px;
    }
  } */

  ${({ isInvisible }) =>
    isInvisible &&
    css`
      ${mediaMin(css`
        opacity: 0;
        visibility: hidden;
      `)}
    `}

  ${({ isInputFocused }) =>
    isInputFocused &&
    css`
      &,
      ${SelectContent}, ${InputWrapper} {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0;
      }

      .select {
        display: block !important;
      }
    `}

  ${mediaMax(
    css`
      min-width: 600px;
    `,
    1280
  )}

  ${mediaMax(
    css`
      min-width: 480px;
    `,
    1140
  )}


  ${mediaMax(
    css`
      min-width: unset;
      max-height: 84px;
      width: 100%;

      ${InputSelectWrapper} {
        width: 100%;
      }

      ${Input} {
        height: 42px;
      }

      ${InputWrapper} {
        width: 100%;
        min-height: 42px;
      }

      .select {
        width: 100%;
        height: 42px;
        position: absolute;
        top: 42px;
        left: 0;
        display: none;

        &__control {
          border-radius: 0;
        }
        &__menu {
          width: 100%;
        }
      }
    `
  )} /* ${mediaMax(
    css`
      ${InputWrapper} {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    `,
    480
  )} */
`

const closeSearchIn = keyframes`
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`

export const ClearButton = styled.button<SearchButtonProps>`
  position: relative;
  position: absolute;
  right: 130px;
  align-items: center;
  cursor: pointer;
  display: flex;
  animation: ${closeSearchIn} 300ms 500ms backwards;
  height: 100%;
  color: #c4c4c4;

  z-index: 4;

  @media screen and (max-width: ${({ theme }) => theme.sizes.mobile}) {
    right: 13px;
  }
`

export const SearchButton = styled.button<SearchButtonProps>`
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);

  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: 300ms;
  width: 24px;
  min-width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.grey_2};
  opacity: 1;

  /* @media screen and (max-width: ${({ theme }) => theme.sizes.mobile}) {
    display: none;
  } */

  svg {
    margin-right: 3px;
    transition: 300ms;
    width: 21px;
    height: 24px;
    color: ${({ theme }) => theme.colors.orange_0};
  }

  span {
    display: none;
    font-family: Baloo;
  }

  ${({ isExpanded, theme }) =>
    isExpanded &&
    mediaMin(css`
      width: 99px;
      min-width: 99px;
      height: 30px;
      border-radius: 5px;
      background-color: ${theme.colors.orange_0} !important;
      span {
        display: flex;
      }
      svg {
        color: ${theme.colors.grey_2};
        width: 14px;
        height: 14px;
      }
    `)}

  ${({ isExpanded }) =>
    mediaMax(css`
      opacity: ${isExpanded ? '0' : '1'};
    `)}
`

export const SearchWrapper = styled.div<{ isMicroHeader?: boolean }>`
  width: 100%;

  ${({ isMicroHeader }) =>
    mediaMin(css`
      position: relative;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      ${isMicroHeader &&
      css`
        margin-left: 34px;
        width: 48px;
        ${Container} {
          position: absolute;
          right: 50px;
          top: 0;
          z-index: 5;
        }

        ${IconWrapper} {
          display: block;
        }
      `}
    `)}
`

export const IconWrapper = styled.div`
  display: none;
  padding-top: 4px;
  cursor: pointer;
`

export const SelectContainer = styled.div`
  position: relative;
  max-width: 78px;
`

export const SelectContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.secondary_1};
  min-width: 78px;
  border-radius: 5px 0 0 5px;
  font-size: 12px;
  cursor: pointer;

  /* @include media-max(1024px) {
    min-height: 42px;
  } */

  .icon-dropdown-arrow__container {
    margin-left: 11px;
  }
`

export const SelectValueWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const SelectValue = styled.span`
  display: -webkit-box;
  max-width: 32px;

  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: none;
`

export const SelectOptionsList = styled.ul`
  position: absolute;
  top: 42px;
  max-height: 379px;
  width: 228px;
  overflow: auto;
  flex-direction: column;
  z-index: 6;
  background-color: #ffffff;
  left: 0;
  border-radius: 5px;
  box-shadow: 3px 0px 5px #0000004d;
`

export const SelectOptionsListItem = styled.li<{ selected?: boolean }>`
  height: 38px;
  min-height: 38px;
  padding-left: 11px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  font-size: 12px;
  /* color: $purple_1; */
  transition: 300ms;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey_4};
  }

  ${({ selected }) =>
    selected &&
    css`
      font-weight: bold;
      cursor: default !important;
      background-color: white !important;
    `}
`
