import styled, { css } from 'styled-components'

export const Container = styled.div`
  padding-bottom: 3px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
`

export const TotalTooltip = styled.span<{ show?: boolean }>`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 40%;
  left: -90%;
  background-color: ${({ theme }) => theme.colors.secondary_0};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  font-family: 'Baloo';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  /* identical to box height */
  transform: scale(0);
  transition: transform 0.3s;

  ${({ show }) =>
    show &&
    css`
      transform: scale(1);
    `}

  color: #91153a;
`
