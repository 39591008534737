import Icon from 'src/components/ui/Icon'

import { AttendanceContainer } from './styles'

function ExclusiveAttendance() {
  return (
    <AttendanceContainer>
      <Icon name="message" size={51} />
      <p>
        <strong>Atendimento Exclusivo</strong>
        <br />
        Fale com a gente pelo
        <br />
        <a
          href="https://api.whatsapp.com/send?phone=5511990120595"
          target="_blank"
          className="highlight"
          rel="noreferrer"
        >
          WhatsApp (11) 9 9012-0595
        </a>
      </p>
    </AttendanceContainer>
  )
}

export default ExclusiveAttendance
