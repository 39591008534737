import { Li } from './styles'

type SecurityItemProps = Omit<
  React.HTMLProps<HTMLAnchorElement>,
  'ref' | 'children' | 'as'
>

const SecurityItem: FCC<SecurityItemProps> = ({ href, children, ...rest }) => {
  return (
    <Li>
      <a href={href} target="_blank" rel="noreferrer" {...rest}>
        {children}
      </a>
    </Li>
  )
}

export default SecurityItem
