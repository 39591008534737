import { ContactContainer } from './styles'
import ExclusiveAttendance from './ExclusiveAttendance'
import CallUs from './CallUs'
import Email from './Email'

function Contact() {
  return (
    <ContactContainer>
      <ExclusiveAttendance />
      <hr />
      <Email />
      <hr />
      <CallUs />
      <hr className="hide-on-desktop" />
    </ContactContainer>
  )
}

export default Contact
