import Icon from 'src/components/ui/Icon'

const payDicio = {
  Bradesco: <Icon name="banco-bradesco" size={23} />,
  Caixa: <Icon name="banco-caixa" width={67} height={21} />,
  Itau: <Icon name="banco-itau" size={21} />,
  Santander: <Icon name="banco-santander" size={23} />,
  Boleto: <Icon name="boleto" width={35} height={23} />,
  Pix: <Icon name="pix" width={59} height={21} />,
  Brasil: <Icon name="banco-brasil" size={23} />,
  Visa: <Icon name="visa" width={32} height={21} />,
  Master: <Icon name="mastercard" width={33} height={21} />,
  Elo: <Icon name="elo" width={33} height={21} />,
  PayPal: <Icon name="paypal" width={23} height={21} />,
  Ame: <Icon name="ame" width={64} height={27} />,
}

interface PaymentItemProps {
  icon: keyof typeof payDicio
}

function PaymentItem({ icon }: PaymentItemProps) {
  return <li>{payDicio[icon]}</li>
}

export default PaymentItem
