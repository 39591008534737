import styled from 'styled-components'

export const InstitucionalContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 640px;
  justify-content: space-between;

  @media screen and (max-width: 1080px) {
    max-width: 500px;
  }

  @media screen and (max-width: 1220px) {
    max-width: 550px;
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    max-width: unset;
  }
`
