import Icon from 'src/components/ui/Icon'

import { EmailContainer } from './styles'

function Email() {
  return (
    <EmailContainer>
      <Icon name="mail" size={51} />
      <p>
        <strong>E-mail</strong>
        <br />
        Envie um e-mail para :
        <br />
        <a href="mailto:sac@miess.com.br">
          <span>sac@miess.com.br</span>
        </a>
      </p>
    </EmailContainer>
  )
}

export default Email
