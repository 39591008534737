import type { SearchEvent } from '@faststore/sdk'
import {
  formatSearchState,
  initSearchState,
  sendAnalyticsEvent,
} from '@faststore/sdk'
import { navigate } from 'gatsby'
import React, {
  forwardRef,
  lazy,
  Suspense,
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useCategoryTree } from 'src/hooks/categoryTree'
import Icon from 'src/components/ui/Icon'
import { throttle } from 'src/utils/throttle'
import dataLayer from 'src/utils/dataLayer'

import * as S from './styles'
import DepartmentSelect from './DepartmentSelect'

const SearchDropdown = lazy(
  () => import('src/components/search/SearchDropdown')
)

export interface SelectOption {
  label: string
  value: string | undefined
}

interface SearchInputProps {
  isMicroHeader?: boolean
  onMicroHeaderInputVisibilityChange?: (isSearchVisible: boolean) => void
}

export type SearchInputRef = HTMLInputElement & { resetSearchInput: () => void }

const SearchInput = forwardRef<SearchInputRef, SearchInputProps>(
  function SearchInput(
    { isMicroHeader, onMicroHeaderInputVisibilityChange },
    _ref
  ) {
    const formRef = useRef<HTMLFormElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const { tree } = useCategoryTree()

    const [searchTerm, setSearchTerm] = useState('')
    const searchQueryDeferred = useDeferredValue(searchTerm)
    const [searchDropdownVisible, setSearchDropdownVisible] = useState(false)
    const [categoryOption, setCategoryOption] = useState<SelectOption>()
    const [isSearchVisible, setIsSearchVisible] = useState(false)

    const blur = useCallback(() => {
      inputRef.current?.blur()
      setSearchDropdownVisible(false)
    }, [])

    const categoryFacet = useMemo<any[]>(
      () =>
        categoryOption?.value
          ? [
              {
                key: 'departamento',
                value: categoryOption?.value,
              },
            ]
          : [],
      [categoryOption]
    )

    const toggleOpenSearch = () => {
      setIsSearchVisible((old) => !old)
    }

    const handleSubmit = useCallback(
      async (e: React.FormEvent) => {
        e.preventDefault()
        if (searchTerm.trim() === '') return

        dataLayer({ event: 'enviou pesquisa' })

        const { pathname, search } = formatSearchState(
          initSearchState({
            term: searchTerm,
            base: '/s',
            selectedFacets: categoryFacet,
          })
        )

        sendAnalyticsEvent<SearchEvent>({
          name: 'search',
          params: { search_term: searchTerm },
        })

        navigate(`${pathname}${search}`)
        blur()
      },
      [blur, categoryFacet, searchTerm]
    )

    const handleInputFocus = useCallback(() => {
      setSearchDropdownVisible(true)
      function handleClickOutside(event: MouseEvent) {
        const form = formRef.current

        // eslint-disable-next-line vtex/prefer-early-return
        if (!form || !form.contains(event.target as Node)) {
          setSearchDropdownVisible(false)
          document.removeEventListener('click', handleClickOutside, true)
        }
      }

      document.addEventListener('click', handleClickOutside, true)
    }, [])

    const selectOptions = useMemo(
      () => [
        ...tree.map((cat) => ({ label: cat.name, value: cat.slug })),
        ...tree[0].children.map((cat) => ({
          label: cat.name,
          value: cat.slug,
        })),
      ],
      [tree]
    )

    useEffect(() => {
      if (typeof window === 'undefined') return

      const removeFocusOnScroll = () => {
        if (!isMicroHeader) {
          return
        }

        // setIsInputFocused(false)
        blur()
        setIsSearchVisible(false)
      }

      window.addEventListener('scroll', throttle(removeFocusOnScroll, 100))

      return () =>
        window.removeEventListener('scroll', throttle(removeFocusOnScroll, 100))
    }, [blur, isMicroHeader])

    useEffect(() => {
      onMicroHeaderInputVisibilityChange?.(isSearchVisible)
    }, [isSearchVisible, onMicroHeaderInputVisibilityChange])

    const handleInput = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value)
      },
      []
    )

    return (
      <S.SearchWrapper isMicroHeader={isMicroHeader}>
        {/* {isMicroHeader && !isPhone && ( */}
        <S.IconWrapper onClick={toggleOpenSearch}>
          <Icon
            name={isSearchVisible ? 'close' : 'search'}
            size={isSearchVisible ? 18 : 24}
          />
        </S.IconWrapper>
        {/* )} */}
        <S.Container
          ref={formRef}
          data-store-form="true"
          data-testid="store-search-input"
          data-store-search-input="true"
          role="search"
          autoComplete="off"
          onSubmit={handleSubmit}
          className="search-input"
          isInputFocused={searchDropdownVisible}
          isInvisible={isMicroHeader && !isSearchVisible}
        >
          <S.InputSelectWrapper>
            <DepartmentSelect
              // id="search-category-select"
              // instanceId="search-category-select"
              // className="select desktop"
              // classNamePrefix="select"
              options={[{ label: 'Todos', value: undefined }, ...selectOptions]}
              // defaultValue={{
              //   label: 'Todos',
              //   value: undefined as string | undefined,
              // }}
              // onFocus={handleSelectFocus}
              // onMenuOpen={() => setIsSelectFocused(true)}
              // onMenuClose={() => setIsSelectFocused(false)}
              onChange={(option) => {
                setCategoryOption(option as SelectOption)

                // if (typeof window !== 'undefined') {
                //   if (window.innerWidth <= 1024) setIsSelectFocused(false)
                // }
              }}
              // maxMenuHeight={380}
              // isSearchable={false}
            />

            <S.InputWrapper>
              {searchDropdownVisible && (
                <S.ClearButton
                  type="button"
                  onClick={() => {
                    setSearchTerm('')
                    // inputRef.current?.focus()
                    blur()
                  }}
                >
                  <Icon name="close" size={16} />
                </S.ClearButton>
              )}

              <S.Input
                ref={inputRef}
                onChange={handleInput}
                value={searchTerm}
                type="text"
                name="se"
                data-store-input="true"
                data-testid="store-input"
                aria-label="search"
                placeholder="O que você procura hoje?"
                onFocus={handleInputFocus}
                minLength={3}
              />
            </S.InputWrapper>
            <S.SearchButton
              type="submit"
              aria-label="Buscar"
              isExpanded={searchDropdownVisible}
            >
              <Icon name="search" size={24} />
              <span>Buscar</span>
            </S.SearchButton>
          </S.InputSelectWrapper>
          {/* <UISearchInput style={{ width: '100%' }} onSubmit={doSearch} {...props} /> */}
          {searchDropdownVisible && (
            <Suspense fallback={null}>
              <SearchDropdown
                term={searchQueryDeferred}
                category={categoryOption}
                onSelect={blur}
                handleSubmit={handleSubmit}
              />
            </Suspense>
          )}
        </S.Container>
      </S.SearchWrapper>
    )
  }
)

export default SearchInput
