/* eslint-disable prefer-regex-literals */
import type { HTMLReactParserOptions, Element } from 'html-react-parser'
import parse, { domToReact, attributesToProps } from 'html-react-parser'
import { Helmet } from 'react-helmet-async'
import { CustomLink } from 'src/components/common/CustomLink'
// import DOMPurify from 'isomorphic-dompurify'

const blockedTagChars = ['>', '<', '/']

interface CustomOptions {
  newTabLinksIfExternal: boolean
  removeLineBreaks: boolean
  spreadParagraphs: boolean
  headingsToStrong: boolean
}

const getOptions = (customOptions: CustomOptions): HTMLReactParserOptions => ({
  replace: (domNode) => {
    const typedDomNode = domNode as Element

    if (typedDomNode.attribs && typedDomNode.name.toLowerCase() === 'a') {
      const { href } = typedDomNode.attribs
      const newHref =
        href
          ?.replace('https://www.miess.com.br', '')
          ?.replace('http://www.miess.com.br', '') ?? '/'

      const { href: _href, ...props } = attributesToProps(typedDomNode.attribs)

      return (
        <CustomLink
          {...props}
          to={newHref}
          newTabIfExternal={customOptions.newTabLinksIfExternal}
        >
          {typedDomNode.children &&
            domToReact(typedDomNode.children, getOptions(customOptions))}
        </CustomLink>
      )
    }

    if (
      typedDomNode.tagName?.toLowerCase() === 'br' &&
      customOptions.removeLineBreaks
    ) {
      return <></>
    }

    if (
      typedDomNode.tagName?.toLowerCase() === 'script' &&
      typedDomNode.attribs.src
    ) {
      const props = attributesToProps(typedDomNode.attribs)

      return (
        <Helmet>
          <script {...props} />
        </Helmet>
      )
    }

    if (
      ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(
        typedDomNode.tagName?.toLowerCase()
      ) &&
      customOptions.headingsToStrong
    ) {
      return (
        <strong
          className={`heading ${typedDomNode.tagName
            ?.toLowerCase()
            .replace('h', 'heading--')}`}
        >
          {typedDomNode.children &&
            domToReact(typedDomNode.children, getOptions(customOptions))}
        </strong>
      )
    }

    if (
      typedDomNode.tagName?.toLowerCase() === 'p' &&
      customOptions.spreadParagraphs
    ) {
      return (
        <>
          {typedDomNode.children &&
            domToReact(typedDomNode.children, getOptions(customOptions))}{' '}
        </>
      )
    }

    if (
      blockedTagChars.some((char) =>
        typedDomNode.name?.toLowerCase().includes(char)
      )
    ) {
      return (
        <pre>
          Tag '{typedDomNode.name?.toLowerCase()}' does not exist. Please fix
          this product's description.
        </pre>
      )
    }

    return false
  },
})

const removeString = (text: string) => {
  const stringsToRemove = ['&lt;', '&gt;', String.fromCharCode(0x2028)]

  return stringsToRemove.reduce(
    (acc, string) => acc.replace(new RegExp(string, 'g'), ''),
    text
  )
}

export const stringToSanitizedHtml = (
  html: string,
  customOptions: Partial<CustomOptions> = {}
) => {
  const defaultCustomOptions: CustomOptions = {
    newTabLinksIfExternal: false,
    removeLineBreaks: false,
    spreadParagraphs: false,
    headingsToStrong: false,
  }

  return parse(
    removeString(html),
    getOptions({ ...defaultCustomOptions, ...customOptions })
  )
}

// parse(DOMPurify.sanitize(html))
