import { mediaMax } from 'src/styles/utils/mediaMax'
import styled, { css } from 'styled-components'

export const LogoBox = styled.section`
  display: none;
  > a {
    width: 104px;
    height: 40px;
    display: flex;
    align-items: center;
    user-select: none;
  }

  ${mediaMax(
    css`
      margin-left: auto;
      margin-right: auto;
    `
  )}
`

export const LogoBoxHome = styled.h1`
  display: none;
  > a {
    width: 104px;
    height: 40px;
    display: flex;
    align-items: center;
    user-select: none;
  }

  ${mediaMax(
    css`
      margin-left: auto;
      margin-right: auto;
    `
  )}
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 42px;

  .icon-hamburger__container {
    display: none;
  }

  ${mediaMax(css`
    margin-bottom: 14px;

    ${LogoBox}, ${LogoBoxHome}, .icon-hamburger__container {
      display: block;
    }
  `)}
`

export const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  height: 40px;
  min-height: 40px;

  ${mediaMax(
    css`
      justify-content: unset;
    `
  )}
`

export const WishlistWrapper = styled.div`
  padding-top: 2px;

  ${mediaMax(
    css`
      padding-top: 2px;
      margin-left: 14px;
    `
  )}
`

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  ${mediaMax(
    css`
      display: flex;
      align-items: center;
      flex: unset;

      > div:first-child {
        margin-right: 30px;
      }
    `
  )}
`
