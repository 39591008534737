import List from '../List'
import InstitucionalItem from '../InstitucionalItem'
import { PaymentContainer, Methods, Institucional } from './styles'
import PaymentMethods from '../PaymentMethods'

function LeftContent() {
  return (
    <PaymentContainer>
      <Institucional>
        <List title="Institucional">
          <InstitucionalItem to="QuemSomos" />
          {/* <InstitucionalItem to="PinkFriday" /> */}
          <InstitucionalItem to="PoliticaPrivacidade" />
          <InstitucionalItem to="TrocasDevolucoes" />
          <InstitucionalItem to="CompraSegura" />
          <InstitucionalItem to="Revendedor" />
        </List>
      </Institucional>
      <Methods>
        <PaymentMethods />
      </Methods>
    </PaymentContainer>
  )
}

export default LeftContent
