import { lazy, Suspense } from 'react'
import { useCartToggleButton } from 'src/sdk/cart/useCartToggleButton'
import { useUI } from 'src/sdk/ui/Provider'
import Icon from 'src/components/ui/Icon'
import { useCart } from 'src/sdk/cart'

import { Container, TotalTooltip } from './styles'

const CartBody = lazy(() => import('src/components/cart/CartBody'))

const Cart: FCC = () => {
  const { cart } = useUI()
  const btnProps = useCartToggleButton(['hover'])
  const { totalItems } = useCart()

  return (
    <Container {...btnProps}>
      <Icon name="bag" width={19} height={23} />
      <TotalTooltip show={!cart}>{totalItems}</TotalTooltip>
      {cart && (
        <Suspense fallback={null}>
          <CartBody />
        </Suspense>
      )}
    </Container>
  )
}

export default Cart
