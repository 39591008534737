import { containerCenter } from 'src/styles/utils/containerCenter'
import { mediaMax } from 'src/styles/utils/mediaMax'
import { mediaMin } from 'src/styles/utils/mediaMin'
import styled, { css } from 'styled-components'
import { SearchWrapper } from 'src/components/search/SearchInput/styles'

import { Container as HeaderCenterContainer } from './HeaderCenter/styles'
import { Container as HeaderTopContainer } from './HeaderTop/styles'
import type { HeaderContainerProps } from './types'

export const Content = styled.div`
  ${containerCenter}
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  /* &.mobile {
    display: none;
  } */

  ${mediaMax(css`
    flex-direction: column;

    .navlinks {
      display: none;
    }

    .desktop {
      display: none;
    }

    /* &.mobile {
      display: flex;
    } */
  `)}
`

export const Wrapper = styled.article``

export const LogoBox = styled.section`
  padding-right: 39px;
  display: flex;
  align-items: center;

  > a {
    box-shadow: none !important;
  }

  ${mediaMax(
    css`
      svg {
        width: 93%;
        height: auto;
      }
    `,
    1200
  )}
`

export const LogoBoxHome = styled.h1`
  padding-right: 39px;
  display: flex;
  align-items: center;

  > a {
    box-shadow: none !important;
  }

  ${mediaMax(
    css`
      svg {
        width: 93%;
        height: auto;
      }
    `,
    1200
  )}
`

export const Main = styled.section`
  width: 100%;
  min-height: 138px;
  ${mediaMax(
    css`
      margin-bottom: 18px;
    `
  )}
`

export const Container = styled.header<HeaderContainerProps>`
  height: 202px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.grey_2};
  color: ${({ theme }) => theme.colors.orange_0};

  ${mediaMax(
    css`
      height: unset;
      ${HeaderCenterContainer} {
        flex-direction: column-reverse;

        margin-bottom: unset;
        gap: 14px;
        height: unset;

        ${SearchWrapper} {
          width: 100%;
        }
      }
    `
  )}

  ${mediaMin(css`
    ${HeaderTopContainer} {
      display: flex;
      align-items: center;
      width: 100%;
      height: 37px;
      border-bottom: 0.8px solid #f0863f4d;
      .swiper-arrow,
      .embla__container {
        display: none;
      }
    }
  `)}

  .top-bar--desktop {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 5px;

    > a {
      color: #f0863f;
      font-size: 11px;
      display: flex;
      align-items: center;
      font-family: 'Baloo';
    }

    svg {
      margin-right: 5px;
    }
  }

  ${mediaMax(css`
    .top-bar--desktop {
      display: none;
    }
  `)}

  ${({ isMicroHeader }) =>
    isMicroHeader &&
    css`
      ${HeaderTopContainer} {
        display: none;
      }

      ${HeaderCenterContainer} {
        flex: 1;
      }

      ${Main} {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
      }
    `}

  ${Wrapper} {
    background-color: ${({ theme }) => theme.colors.grey_2};

    ${({ isMicroHeader, isVisible, isTransitionActive, cart }) =>
      isMicroHeader &&
      css`
        position: fixed;
        left: 0;
        right: 0;
        top: 0;

        transform: translateY(-260px);
        z-index: 6;

        ${isTransitionActive &&
        css`
          transition: all 0.2s;
        `}

        ${cart &&
        css`
          z-index: 10;
        `}

        ${isVisible &&
        css`
          transform: translateY(0px);
        `}

        ${mediaMin(css`
          ${Content} {
            height: 70px;
          }
        `)}

        ${mediaMax(css`
          ${Content} {
            padding-top: 22px;
          }
        `)}
      `}
  }
`
