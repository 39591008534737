import styled from 'styled-components'

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;

  ul {
    margin-top: 6px;
  }
  li > a {
    display: flex;
    font-size: 13px;
    line-height: 220%;
  }

  ul > li:last-child {
    color: ${({ theme }) => theme.colors.white_1};
    font-weight: 400;
  }
`
export const MidContainer = styled.div`
  display: none;
  @media (max-width: 940px) {
    display: inline;

    ul {
      display: flex;
      justify-content: left;

      li {
        margin-right: 10px;
        margin-top: 5px;
      }
    }
  }
`
export const BotContainer = styled.div`
  ul {
    display: flex;
    margin-top: 10px;

    li {
      margin-right: 8px;
    }
  }

  .icon {
    width: 71px;
  }
  @media screen and (max-width: 940px) {
    ul {
      justify-content: left;
      align-items: center;

      li {
        margin-right: 20px;
      }
    }
  }
`
