import './src/styles/fonts.css'
import './src/styles/global/tokens.scss'
import './src/styles/global/resets.scss'
import './src/styles/global/typography.scss'
import './src/styles/global/layout.scss'
import './src/styles/global/components.scss'
import './src/styles/global/trustvoxWidget.scss'
import './src/styles/global/trustvoxSincero.scss'

import UIProvider from 'src/sdk/ui/Provider'
import type { GatsbyBrowser } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import { ModalProvider } from 'src/sdk/ui/modal'

import Layout from './src/Layout'
import AnalyticsHandler from './src/sdk/analytics'
import ErrorBoundary from './src/sdk/error/ErrorBoundary'
import TestProvider from './src/sdk/tests'
import { theme } from './src/styles/theme'
import AppProvider from './src/hooks'
import './src/utils/supressReactErrors'

const ThemeProviderProxy: any = ThemeProvider

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => (
  <ErrorBoundary>
    <ThemeProviderProxy theme={theme}>
      <AnalyticsHandler />
      <TestProvider>
        <UIProvider>
          <AppProvider>
            <ModalProvider>{element}</ModalProvider>
          </AppProvider>
        </UIProvider>
      </TestProvider>
    </ThemeProviderProxy>
  </ErrorBoundary>
)

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  routerProps: { location },
}) => {
  if (location.hash !== '') {
    return false
  }

  const url = new URL(location.href)

  const shouldScroll = !(
    url.searchParams.has('facets') && url.searchParams.has('sort')
  )

  return shouldScroll
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
}) => {
  return <Layout>{element}</Layout>
}

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location }) => {
  window.scrollTo({ top: 0, left: 0 })

  if (process.env.NODE_ENV !== 'production') {
    return null
  }

  const pagePath = location
    ? location.pathname + location.search + location.hash
    : undefined

  setTimeout(() => {
    // if (typeof gtag === 'function') {
    try {
      // console.log('on route change', {
      //   event: 'page_view',
      //   page_path: pagePath,
      // })
      window.dataLayer.push({ event: 'page_view', page_path: pagePath })
    } catch {
      console.log('Error pushing to dataLayer')
    }
    // gtag('event', 'page_view', { page_path: pagePath })
    // }
  }, 100)

  return null
}
