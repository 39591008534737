import type { EmblaBreakpoints } from 'src/components/ui/Embla/types'
import { css } from 'styled-components'

export const emblaBreakpoints = (breakpoints: EmblaBreakpoints) => {
  const styles = Object.entries(breakpoints)
    .map(
      ([key, value]) => `
        @media screen and (min-width: ${key}px) {
          min-width: calc(100% / ${value});
        }
      `
    )
    .join(' ')

  return css`
    ${styles}
  `
}
