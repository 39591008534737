import { css } from 'styled-components'

import { mediaMin } from './utils/mediaMin'
import { mediaMax } from './utils/mediaMax'

const states = css`
  .hide {
    display: none;
  }

  .HIDE {
    display: none !important;
  }

  .show {
    display: block;
  }

  ${mediaMax(css`
    .hide-on-mobile {
      display: none !important;
    }

    .hide-on-desktop {
      display: flex;
    }
  `)}

  ${mediaMin(css`
    .hide-on-mobile {
      display: flex;
    }

    .hide-on-desktop {
      display: none !important;
    }
  `)}
`

export default states
