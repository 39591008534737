import { CustomLink } from 'src/components/common/CustomLink'

export const CustomLinkDicio = {
  QuemSomos: (
    <CustomLink to="/institucionais/AntesCompra/QuemSomos">
      Quem Somos
    </CustomLink>
  ),
  PinkFriday: <CustomLink to="/">Pink Friday</CustomLink>,
  PoliticaPrivacidade: (
    <CustomLink to="/institucionais/AntesCompra/PoliticaPrivacidade">
      Política de Privacidade
    </CustomLink>
  ),
  TrocasDevolucoes: (
    <CustomLink to="/institucionais/PosCompra/TrocasDevolucoes">
      Trocas e Devoluções
    </CustomLink>
  ),
  CompraSegura: (
    <CustomLink to="/institucionais/Antescompra/CompraSegura">
      Compra Segura
    </CustomLink>
  ),
  Revendedor: (
    <CustomLink to="/revenda-catalogo">Seja um revendedor</CustomLink>
  ),
}

interface ListItemProps {
  to: keyof typeof CustomLinkDicio
}

const InstitucionalItem: FCC<ListItemProps> = ({ to }) => {
  return <li>{CustomLinkDicio[to]}</li>
}

export default InstitucionalItem
