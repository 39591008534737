import PaymentItem from '../LeftContent/PaymentList/PaymentItem'
import List from '../List'
import { PaymentContainer } from './styles'

function PaymentMethods() {
  return (
    <PaymentContainer>
      <List title="Metodos de pagamento">
        <PaymentItem icon="Boleto" />
        <PaymentItem icon="Brasil" />
        <PaymentItem icon="Santander" />
        <PaymentItem icon="Caixa" />
        <PaymentItem icon="Bradesco" />
        <PaymentItem icon="Itau" />
        <PaymentItem icon="Pix" />
        <PaymentItem icon="Master" />
        <PaymentItem icon="Visa" />
        <PaymentItem icon="Elo" />
        <PaymentItem icon="PayPal" />
      </List>
    </PaymentContainer>
  )
}

export default PaymentMethods
