import { mediaMax } from 'src/styles/utils/mediaMax'
import styled, { css } from 'styled-components'

import { CustomLink } from '../../CustomLink'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  position: relative;
  z-index: 5;

  svg {
    height: 23px;
  }
`

export const Container = styled.div`
  display: flex;
  margin-left: 36px;
  height: 100%;
  cursor: pointer;

  > p {
    display: flex;
    flex-direction: column;
    color: #f0863f;
    font-size: 12px;
    font-weight: 400;
    width: 115px;

    line-height: 14px;

    > span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    > b {
      font-weight: normal;
      font-family: Baloo;
    }
  }

  ${mediaMax(css`
    margin-left: 0;
    ${Content} {
      margin-right: 0;
    }
    > p {
      display: none;
    }
  `)}

  &:hover {
    .box {
      opacity: 1;
      visibility: visible;

      transition-delay: 0s;
    }
  }
`

export const Box = styled.div`
  position: absolute;
  top: 42px;
  background-color: white;
  flex-direction: column;
  min-width: 140px;
  border-radius: 5px;
  align-items: center;
  box-shadow: 0px 0px 5px #0000004d;
  display: flex;

  opacity: 0;
  visibility: hidden;

  transition: all 0.2s;
  transition-delay: 0.2s;

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 9px 9px;
    border-color: transparent transparent #ffffff transparent;
    position: absolute;
    top: -9px;
  }
`

interface BoxItemLinkInterface {
  logout?: boolean
}

export const BoxItemLink = styled(CustomLink)<BoxItemLinkInterface>`
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fafafa;
  border-radius: 5px;
  font-size: 12px;
  color: #361328;
  width: 100%;
  transition: all 0.2s;

  ${({ theme, logout }) =>
    logout &&
    css`
      color: ${theme.colors.danger_0};
    `}

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey_5};
  }
`
