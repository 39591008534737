import styled, { css } from 'styled-components'

import type { IconBoxTypes, SvgUseContainerProps } from './types'

export const IconBox = styled.div<IconBoxTypes>`
  width: fit-content;
  display: flex;
  margin: ${({ margin }) => margin ?? margin};

  svg {
    ${({ width }) =>
      width &&
      css`
        width: ${width};
      `}
    height: auto;

    ${({ color }) =>
      color &&
      css`
        & * {
          fill: ${color};
          color: ${color};
        }
      `}
  }
`

export const SvgUseContainer = styled.svg<SvgUseContainerProps>`
  margin: ${({ margin }) => margin ?? margin};
  ${({ color }) =>
    color &&
    css`
      & * {
        fill: ${color};
        color: ${color};
      }
    `}
`
