import styled from 'styled-components'

export const CallUsContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  width: 240px;
`

export const TextContainer = styled.div`
  margin-left: 15px;

  .white {
    color: ${({ theme }) => theme.colors.white_1};
    font-weight: 700;
  }

  .sub {
    margin-top: 15px;
  }
`
