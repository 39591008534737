import { useEffect, useState } from 'react'
// import { UsingCookiesAlert } from 'src/components/UsingCookiesAlert'
import { useUI } from 'src/sdk/ui/Provider'
import { throttle } from 'src/utils/throttle'
import { globalHistory } from '@reach/router'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import NewLogo from 'src/components/ui/Logo/NewLogo'

import Navlinks from './Navlinks'
import {
  Container,
  Wrapper,
  LogoBox,
  LogoBoxHome,
  Main,
  Content,
} from './styles'
import { HeaderTopMobile } from './HeaderTop'
import HeaderCenter from './HeaderCenter'
import FreeShippingBar from '../FreeShippingBar'
import InfoBar from './InfoBar'
import TopBar from './TopBar'
import SideBar from './SideBar/SideBar'
import { CustomLink } from '../CustomLink'

const Header: FCC = () => {
  const { cart, closeCart, navbar: showMenu } = useUI()

  // const [mounted, setMounted] = useState(() => false)

  const [isMicroHeader, setIsMicroheader] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [isTransitionActive, setIsTransitionActive] = useState(false)
  const [isNavVisible, setIsNavVisible] = useState(false)
  const [isHome, setIsHome] = useState(false)
  const { isPhone } = useWindowDimensions()

  useEffect(() => {
    if (typeof window === 'undefined') return

    setTimeout(() => setIsHome(window?.location.pathname === '/'))

    return globalHistory.listen(({ action, location }) => {
      if (action === 'PUSH') {
        setIsHome(location.pathname === '/')
      }
    })
  }, [])

  useEffect(() => {
    let lastScrollTop = 0

    const setMicroheader = () => {
      const currentScroll = window.scrollY

      if (currentScroll > 250) {
        setIsMicroheader(true)

        if (currentScroll > 500) {
          setIsTransitionActive(true)
        } else {
          setIsTransitionActive(false)
        }

        if (lastScrollTop > currentScroll && currentScroll > 700) {
          setIsVisible(true)
        } else {
          setIsVisible(false)

          if (cart) {
            closeCart()
          }
        }
      } else {
        setIsMicroheader(false)
      }

      lastScrollTop = currentScroll
    }

    window.addEventListener('scroll', throttle(setMicroheader, 30))

    return () =>
      window.removeEventListener('scroll', throttle(setMicroheader, 30))
  }, [closeCart, cart])

  useEffect(() => {
    if (cart) setIsVisible(true)
  }, [cart])

  useEffect(() => {
    console.log({ isNavVisible })
  }, [isNavVisible])

  // useEffect(() => {
  //   setMounted(true)
  // }, [])

  useEffect(() => {
    if (showMenu) {
      document.documentElement.style.overflowY = 'hidden'

      return
    }

    document.documentElement.style.overflowY = 'auto'
  }, [showMenu])

  return (
    <>
      <Container
        isVisible={isVisible}
        isMicroHeader={isMicroHeader}
        isTransitionActive={isTransitionActive}
        cart={cart}
      >
        <TopBar />
        <Wrapper>
          <Content key="desktop" className="desktop">
            {isHome && !isPhone ? (
              <LogoBoxHome className="desktop">
                <CustomLink className="m-1" to="/">
                  <NewLogo />
                </CustomLink>
              </LogoBoxHome>
            ) : (
              <LogoBox className="desktop">
                <CustomLink className="m-1" to="/">
                  <NewLogo />
                </CustomLink>
              </LogoBox>
            )}
            <Main>
              {!isMicroHeader && <HeaderTopMobile />}
              <HeaderCenter
                isMicroHeader={isMicroHeader}
                onMicroHeaderInputVisibilityChange={setIsNavVisible}
              />
              <Navlinks isInvisible={isNavVisible} />
            </Main>
          </Content>
        </Wrapper>
        {/* <Wrapper>
          <Content key="mobile" className="mobile">
            {!isMicroHeader && <HeaderTopMobile />}
            <Main>
              <HeaderCenter
                isMicroHeader={isMicroHeader}
                onMenuClick={openNavbar}
              />
            </Main>
          </Content>
        </Wrapper> */}
        {showMenu && <SideBar />}
      </Container>
      <InfoBar />
      <FreeShippingBar />
      {/* {mounted && <UsingCookiesAlert />} */}
    </>
  )
}

export default Header
