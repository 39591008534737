import { useCallback } from 'react'

import { useUI } from '../ui/Provider'
import { useCart } from './index'

export const useCartToggleButton = (
  mode: Array<'click' | 'hover'> = ['click']
) => {
  const { totalItems } = useCart()
  const { cart: displayCart, openCart, closeCart } = useUI()

  const toggle = useCallback(() => {
    if (displayCart) {
      closeCart()
    } else {
      openCart()
    }
  }, [closeCart, displayCart, openCart])

  const onClick = useCallback(
    (event: any) => {
      if (mode.includes('click') && window.outerWidth >= 1024) {
        return
      }

      const a = event.target as Element

      if (displayCart) {
        if (
          a.classList.contains('icon-close__container') ||
          (a.parentNode as Element).classList.contains('icon-close__container')
        ) {
          closeCart()
        }

        return
      }

      toggle()
    },
    [closeCart, displayCart, mode, toggle]
  )

  const onMouseEnter = useCallback(
    () => mode.includes('hover') && window.outerWidth >= 1024 && openCart(),
    [mode, openCart]
  )

  return {
    onClick,
    onMouseEnter,
    'data-testid': 'cart-toggle',
    'data-items': totalItems,
  }
}
