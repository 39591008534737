import styled from 'styled-components'

export const MidContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
  }
`
export const MidListContainer = styled.div`
  ul {
    margin-top: 6px;
  }
  li > a {
    display: flex;
    font-size: 13px;

    line-height: 220%;
  }

  ul > li:last-child {
    color: ${({ theme }) => theme.colors.white_1};
    font-weight: 400;
  }
  H3 {
    padding-bottom: 5px;
  }
`

export const TermPayment = styled.div`
  width: 150px;

  ul {
    display: flex;
    flex-wrap: wrap;
  }

  ul > li {
    margin-right: 10px;
    margin-top: 10px;
  }
  span {
    font-family: 'Baloo 2';
    font-size: 11px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`
