import { useInfoBar } from 'src/hooks/infoBar'

import LinkItem from './LinkItem'
import * as S from './styles'

const InfoBar: FCC = () => {
  const { data } = useInfoBar()

  return (
    <S.Container>
      <S.Nav>
        <S.LinkNavList>
          {data.map((link, index) => (
            <LinkItem key={index} {...link} />
          ))}
        </S.LinkNavList>
      </S.Nav>
    </S.Container>
  )
}

export default InfoBar
