import { AuthProvider } from './auth'
import { BadgesProvider } from './badges'
import { CategoryTreeProvider } from './categoryTree'
import { InfoBarProvider } from './infoBar'
import { QuickViewProvider } from './quickView'
import { WishlistProvider } from './wishlist'

const AppProvider: FCC = ({ children }) => (
  <BadgesProvider>
    <CategoryTreeProvider>
      <InfoBarProvider>
        <AuthProvider>
          <WishlistProvider>
            <QuickViewProvider>{children}</QuickViewProvider>
          </WishlistProvider>
        </AuthProvider>
      </InfoBarProvider>
    </CategoryTreeProvider>
  </BadgesProvider>
)

export default AppProvider
