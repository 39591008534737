import { useCallback, useState } from 'react'
import { CustomLink } from 'src/components/common/CustomLink'
import { Image } from 'src/components/ui/Image'
import { toClassname } from 'src/utils/toClassname'

import * as S from './styles'
import type { InfoLinkItemProps } from './types'

const LinkItem: FCC<InfoLinkItemProps> = ({
  bold,
  hoverPanel,
  link,
  newTab,
  text,
}) => {
  const [forceClose, setForceClose] = useState(false)

  const close = useCallback(() => {
    setForceClose(true)
    // setTimeout(() => setForceClose(false))
  }, [])

  const handleMouseLeave = useCallback(() => {
    setForceClose(false)
  }, [])

  return (
    <S.Container bold={!!bold} onMouseLeave={handleMouseLeave}>
      <CustomLink to={link ?? '/'} newTab={!!newTab} onClick={close}>
        {text}
      </CustomLink>
      {!!hoverPanel?.subLinks?.length && (
        <S.HoverPanel className={toClassname([forceClose && 'close'])}>
          <S.HoverPanelContent>
            {hoverPanel.images?.leftImage?.srcSet && (
              <CustomLink
                to={hoverPanel.images?.leftImage?.link ?? '/'}
                onClick={close}
              >
                <Image
                  src={hoverPanel.images?.leftImage?.srcSet as string}
                  height={300}
                  width={200}
                  alt={hoverPanel.images?.leftImage?.alt ?? ''}
                  title={hoverPanel.images?.leftImage?.alt ?? ''}
                  loading="lazy"
                  options={{
                    fitIn: true,
                  }}
                />
              </CustomLink>
            )}
            <S.ListWrapper
              hasLeftImage={!!hoverPanel.images?.leftImage?.srcSet}
            >
              <S.Title>{hoverPanel.title}</S.Title>
              <S.List>
                {hoverPanel.subLinks.map((linkItem) => (
                  <S.ListItem
                    key={`${linkItem?.link}-${linkItem?.text}`}
                    highlighted={!!linkItem?.highlighted}
                  >
                    <CustomLink to={linkItem?.link ?? '/'} onClick={close}>
                      {linkItem?.text}
                    </CustomLink>
                  </S.ListItem>
                ))}
              </S.List>
            </S.ListWrapper>
            {hoverPanel.images?.mosaicLeftImage?.link && (
              <CustomLink
                to={hoverPanel.images?.mosaicLeftImage?.link ?? '/'}
                onClick={close}
              >
                <Image
                  src={hoverPanel.images?.mosaicLeftImage?.srcSet as string}
                  height={300}
                  width={200}
                  alt={hoverPanel.images?.mosaicLeftImage?.alt ?? ''}
                  loading="lazy"
                  options={{
                    fitIn: true,
                  }}
                />
              </CustomLink>
            )}
            {hoverPanel.images?.mosaicTopRightImage?.link &&
              hoverPanel.images?.mosaicBottomRightImage?.link && (
                <S.MosaicRightWrapper>
                  <CustomLink
                    to={hoverPanel.images?.mosaicTopRightImage?.link ?? '/'}
                    onClick={close}
                  >
                    <Image
                      src={
                        hoverPanel.images?.mosaicTopRightImage?.srcSet as string
                      }
                      width={190}
                      height={145}
                      alt={hoverPanel.images?.mosaicTopRightImage?.alt ?? ''}
                      loading="lazy"
                      options={{
                        fitIn: true,
                      }}
                    />
                  </CustomLink>
                  <CustomLink
                    to={hoverPanel.images?.mosaicBottomRightImage?.link ?? '/'}
                    onClick={close}
                  >
                    <Image
                      src={
                        hoverPanel.images?.mosaicBottomRightImage
                          ?.srcSet as string
                      }
                      width={190}
                      height={145}
                      alt={hoverPanel.images?.mosaicBottomRightImage?.alt ?? ''}
                      loading="lazy"
                      options={{
                        fitIn: true,
                      }}
                    />
                  </CustomLink>
                </S.MosaicRightWrapper>
              )}
          </S.HoverPanelContent>
        </S.HoverPanel>
      )}
    </S.Container>
  )
}

export default LinkItem
