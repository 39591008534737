import type { RootCategoriesWithBlocksQuery } from '@generated/graphql'
import { graphql, useStaticQuery } from 'gatsby'
import { createContext, useCallback, useContext, useMemo } from 'react'
import type { DeepNonNullable } from 'src/typings/DeepNonNullable'

import type { Category, CategoryTreeContextData } from './types'

const CategoryTreeContext = createContext<CategoryTreeContextData>(
  {} as CategoryTreeContextData
)

const CategoryTreeProvider: FCC = ({ children }) => {
  const rawCategories = useStaticQuery<
    DeepNonNullable<RootCategoriesWithBlocksQuery>
  >(graphql`
    query RootCategoriesWithBlocks {
      allStoreCategory {
        edges {
          node {
            id: remoteId
            name
            slug
            treePath
          }
        }
      }
      cmsStoreConfig {
        categoryBlocks {
          categoryBlocks {
            blocks {
              icon
              name
              id
            }
          }
        }
      }
    }
  `)

  const categories = useMemo(() => {
    return (
      rawCategories.allStoreCategory.edges
        // const categories: Category[] = ([] as any[])
        .map(({ node }) => node)
        .filter((cat) => cat.id !== '1')
    )
  }, [rawCategories.allStoreCategory.edges])

  const tree = useMemo(() => {
    const rawTree: Category[] = categories
      // const categories: Category[] = ([] as any[])
      .map((cat) => ({
        ...cat,
        // id: cat.node.id.split(':')[0],
        children: [],
      }))

    const categoryTree = rawTree.reduce((acc, cat) => {
      if (cat.treePath?.length === 1) {
        acc.push(cat)
      } else if (cat.treePath?.length === 2) {
        const parent = acc.find((rootCat) => rootCat.id === cat.treePath?.[0])

        parent?.children.push(cat)
      } else if (cat.treePath?.length === 3) {
        for (const a of acc) {
          const child = a.children.find(
            (rootCat) => rootCat.id === cat.treePath?.[1]
          )

          child?.children.push(cat)
        }
      }

      return acc
    }, [] as Category[])

    // Sort root categories' children
    categoryTree.forEach((cat) => {
      cat.children.sort((a, b) => a.name.localeCompare(b.name))
    })

    return categoryTree
  }, [categories])

  const getCategoryById = useCallback(
    (id: string): Category | undefined => {
      function searchTree(
        element: any,
        matchingId: string
      ): Category | undefined {
        if (element.id === matchingId) {
          return element
        }

        if (element.children !== undefined) {
          let result

          for (
            let i = 0;
            result === undefined && i < element.children.length;
            i++
          ) {
            result = searchTree(element.children[i], matchingId)
          }

          return result
        }

        return undefined
      }

      return searchTree({ children: tree, id: '0' }, id)

      // for (const obj of tree) {
      //   if (obj.id === id) {
      //     return obj
      //   }

      //   const searchFunc = (objSearch: Category) =>
      //     objSearch.children.find((child) => child.id === id)

      //   const search = searchFunc(obj)

      //   if (search) {
      //     return search
      //   }
      // }

      // return undefined
    },
    [tree]
  )

  const getCategoryBySlug = useCallback(
    (slug: string) => {
      function searchTree(
        element: Category,
        matchingSlug: string
      ): Category | undefined {
        if (element.slug === matchingSlug) {
          return element
        }

        if (element.children !== undefined) {
          let result

          for (
            let i = 0;
            result === undefined && i < element.children.length;
            i++
          ) {
            result = searchTree(element.children[i], matchingSlug)
          }

          return result
        }

        return undefined
      }

      return searchTree(
        { children: tree, id: '0', slug: '', name: 'root' },
        slug
      )
    },
    [tree]
  )

  const getCategoryBlockData = useCallback(
    (id: string | number) => {
      const category = getCategoryById(String(id))

      if (!category) {
        return undefined
      }

      const block =
        rawCategories.cmsStoreConfig?.categoryBlocks?.categoryBlocks?.blocks?.find(
          (cat) => String(cat?.id) === String(id)
        )

      if (!block) {
        return {
          slug: category.slug,
          name: category.name,
        }
      }

      return {
        slug: category.slug,
        ...block,
        name: block.name ? block.name : category.name,
      }
    },
    [
      getCategoryById,
      rawCategories.cmsStoreConfig?.categoryBlocks?.categoryBlocks?.blocks,
    ]
  )

  return (
    <CategoryTreeContext.Provider
      value={{
        tree,
        getCategoryById,
        getCategoryBySlug,
        getCategoryBlockData,
      }}
    >
      {children}
    </CategoryTreeContext.Provider>
  )
}

function useCategoryTree(): CategoryTreeContextData {
  const context = useContext(CategoryTreeContext)

  if (!context) {
    throw new Error(
      'useCategoryTree must be used within a CategoryTreeProvider'
    )
  }

  return context
}

export { CategoryTreeProvider, useCategoryTree }
