import React, { useState, useEffect, useCallback } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import { toClassname } from 'src/utils/toClassname'

import * as S from '../styles'
import type { EmblaWithThumbsProps } from '../types'
import Icon from '../../Icon'
import ThumbsContainer from './ThumbsContainer'
import { Image } from '../../Image'
import InnerImageZoom from './InnerImageZoom'

const getImgSrc = (src: string, size: number) =>
  `https://miess.vtexassets.com/unsafe/fit-in/${size}x${size}/center/middle/${src}`

const EmblaWithThumbs: FCC<EmblaWithThumbsProps> = ({
  children,
  sliderPerView,
  breakpoints,
  gridRows,
  fullSlide,
  navigation,
  thumbsContainerRef,
  thumbsBreakpoints,
  thumbsNavigation,
  zoom,
  images,
  baseSize = 540,
  onEmbla,
  ...rest
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [mainViewportRef, emblaApi] = useEmblaCarousel({
    slidesToScroll: 1,
    draggable: true,
    align: 'start',
    containScroll: 'trimSnaps',
    skipSnaps: false,
    ...rest,
  })

  const [thumbViewportRef, emblaThumbsApi] = useEmblaCarousel({
    // align: 'start',
    containScroll: 'keepSnaps',
    dragFree: true,
    loop: true,
  })

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)

  const onThumbClick = useCallback(
    (index: number) => {
      if (!emblaApi || !emblaThumbsApi) return
      if (emblaThumbsApi.clickAllowed()) emblaApi.scrollTo(index)
    },
    [emblaApi, emblaThumbsApi]
  )

  const onSelect = useCallback(() => {
    if (!emblaApi || !emblaThumbsApi) return
    setPrevBtnEnabled(emblaApi.canScrollPrev())
    setNextBtnEnabled(emblaApi.canScrollNext())
    setSelectedIndex(emblaApi.selectedScrollSnap())
    emblaThumbsApi.scrollTo(emblaApi.selectedScrollSnap())
  }, [emblaApi, emblaThumbsApi])

  useEffect(() => {
    if (!emblaApi) return
    emblaApi.reInit({ loop: true })
    onSelect()
    onEmbla?.(emblaApi)
    emblaApi.on('select', onSelect)
  }, [emblaApi, onSelect, onEmbla, images])

  const scrollPrev = useCallback(() => emblaApi?.scrollPrev(), [emblaApi])
  const scrollNext = useCallback(() => emblaApi?.scrollNext(), [emblaApi])

  const getSlideClassname = useCallback(
    (index: number, isThumb = false) => {
      const scrollSnaps = emblaApi?.scrollSnapList() ?? []

      const classes = [
        'embla__slide',
        index === selectedIndex && 'embla__slide__active',
        index === 0 && 'embla__slide__first',
        index === scrollSnaps.length - 1 && 'embla__slide__last',
        index === selectedIndex - 1 && 'embla__slide__prev',
        index === selectedIndex + 1 && 'embla__slide__next',
        isThumb && 'embla__slide__thumb',
        // isThumb && 'embla__thumb',
      ]

      return toClassname(classes)
    },
    [emblaApi, selectedIndex]
  )

  return (
    <>
      <S.EmblaContainer
        ref={mainViewportRef}
        sliderPerView={sliderPerView}
        breakpoints={breakpoints}
        gridRows={gridRows}
        fullSlide={fullSlide}
        className="embla__container"
      >
        <S.EmblaContent className="embla__container">
          {images.map((item, index) => (
            <S.EmblaSlide key={index} className={getSlideClassname(index)}>
              {zoom ? (
                <InnerImageZoom
                  key={index}
                  src={item.url}
                  zoomSrc={getImgSrc(item.url, 1200)}
                  imgAttributes={{
                    loading: index === 0 ? 'eager' : 'lazy',
                    alt: item.alternateName,
                    title: item.alternateName,
                    sizes: '(max-width: 500px) 432px, 540px',
                    decoding: 'async',
                    // sourceHeight

                    // srcSet: '',
                    // noFactors: true,
                    // factors: [0.8, 1],
                    srcSetData: [432, 540],
                  }}
                  // zoomPreload
                  zoomType="hover"
                  zoomScale={1.5}
                  width={baseSize}
                  height={baseSize}
                />
              ) : (
                <Image
                  src={item.url}
                  alt={item.alternateName}
                  width={baseSize}
                  height={baseSize}
                  options={{
                    fitIn: true,
                  }}
                  sizes="100vw"
                  loading={index === 0 ? 'eager' : 'lazy'}
                  srcSet=""
                  noFactors
                  style={{
                    maxHeight: `${baseSize}px`,
                    objectFit: 'none',
                  }}
                />
              )}
            </S.EmblaSlide>
          ))}
        </S.EmblaContent>
      </S.EmblaContainer>
      {navigation && (
        <>
          <button
            type="button"
            className={`swiper-arrow prev-arrow ${
              !prevBtnEnabled ? 'swiper-button-disabled' : ''
            } ${
              typeof navigation !== 'boolean' && navigation.circle
                ? 'circle'
                : ''
            }`}
            onClick={scrollPrev}
          >
            {(navigation as any).component ?? <Icon name="arrow" />}
          </button>
          <button
            type="button"
            className={`swiper-arrow next-arrow ${
              !nextBtnEnabled ? 'swiper-button-disabled' : ''
            } ${
              typeof navigation !== 'boolean' && navigation.circle
                ? 'circle'
                : ''
            }`}
            onClick={scrollNext}
          >
            {(navigation as any).component ?? <Icon name="arrow" />}
          </button>
        </>
      )}

      {/* THUMB EMBLA */}
      {thumbsContainerRef?.current ? (
        <ThumbsContainer element={thumbsContainerRef.current}>
          <S.EmblaContainer
            ref={thumbViewportRef}
            sliderPerView={sliderPerView}
            breakpoints={thumbsBreakpoints}
            gridRows={gridRows}
            fullSlide={fullSlide}
            className="embla__container"
          >
            <S.EmblaContent className="embla__content">
              {images.map((item, index) => (
                <S.EmblaSlide
                  key={index}
                  className={getSlideClassname(index, true)}
                  onClick={() => onThumbClick(index)}
                >
                  <Image
                    src={item.url}
                    alt={item.alternateName}
                    title={item.alternateName}
                    width={95}
                    height={95}
                    loading="eager"
                    srcSet=""
                    noFactors
                    options={{
                      fitIn: true,
                    }}
                    style={{
                      objectFit: 'none',
                    }}
                  />
                </S.EmblaSlide>
              ))}
            </S.EmblaContent>
          </S.EmblaContainer>
          {thumbsNavigation && (
            <>
              <button
                type="button"
                className={`swiper-arrow prev-arrow ${
                  !prevBtnEnabled ? 'swiper-button-disabled' : ''
                } ${
                  typeof thumbsNavigation !== 'boolean' &&
                  thumbsNavigation.circle
                    ? 'circle'
                    : ''
                }`}
                onClick={scrollPrev}
              >
                {(thumbsNavigation as any).component ?? <Icon name="arrow" />}
              </button>
              <button
                type="button"
                className={`swiper-arrow next-arrow ${
                  !nextBtnEnabled ? 'swiper-button-disabled' : ''
                } ${
                  typeof thumbsNavigation !== 'boolean' &&
                  thumbsNavigation.circle
                    ? 'circle'
                    : ''
                }`}
                onClick={scrollNext}
              >
                {(thumbsNavigation as any).component ?? <Icon name="arrow" />}
              </button>
            </>
          )}
        </ThumbsContainer>
      ) : null}
    </>
  )
}

export default React.memo(EmblaWithThumbs)
