import React from 'react'
import type { InnerImageZoomProps as OriginalInnerImageZoomProps } from 'react-inner-image-zoom'
import OriginalInnerImageZoom from 'react-inner-image-zoom'

import type { ImageOptions } from '../../Image/useImage'
import { useImage } from '../../Image/useImage'

// import { Container } from './styles';

interface InnerImageZoomProps
  extends Omit<
    OriginalInnerImageZoomProps,
    'width' | 'height' | 'imgAttributes'
  > {
  width: any
  height: any
  imgAttributes: Omit<ImageOptions, 'width' | 'height' | 'src'>
}

const ProxyInnerImageZoom: FCC<OriginalInnerImageZoomProps> =
  OriginalInnerImageZoom as any

const InnerImageZoom: React.FC<InnerImageZoomProps> = (props) => {
  const { width, height, imgAttributes, src } = props

  const {
    width: resultWidth,
    height: resultHeight,
    src: resultSrc,
    ...imgProps
  } = useImage({ src, width, height, ...imgAttributes })

  return (
    <ProxyInnerImageZoom
      {...props}
      width={
        typeof resultWidth === 'string'
          ? Number(resultWidth.replace('px', ''))
          : resultWidth
      }
      height={
        typeof resultHeight === 'string'
          ? Number(resultHeight.replace('px', ''))
          : resultHeight
      }
      src={resultSrc ?? src}
      imgAttributes={imgProps}
    />
  )
}

export default InnerImageZoom
